import React, { useEffect, useState, useCallback } from "react";
import { DataGrid } from '@mui/x-data-grid';
import Link from '@mui/material/Link';
import SpinnerComponent from '../LoadingSpinner';
import axios from 'axios';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import DeleteIcon from "@mui/icons-material/Delete";
//import EditIcon from '@mui/icons-material/Edit';
import CreateEdit from "../CreateEdit";
import MultipleControlSelect from '../MultipleControlSelect';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import AddIcon from '@mui/icons-material/Add';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';

//This class displays lists of controls

export async function loadData(frameworkVersionId) {
    //call data endpoint for data type to set rows and columns
    var url = `${process.env.REACT_APP_API_URL}/framework_versions/${frameworkVersionId}`

    const response = axios(url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.access_token}`,
        },
    }).then(result => {
        return result;
    }).catch(error => { return error.message; })
    return response;
}

export async function deleteData(id) {
    var body;
    var url = `${process.env.REACT_APP_API_URL}/controls/${id}`;
    const response = axios(url, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.access_token}`,
        },
        data: body,
    }).then(result => {
        return result;
    }).catch(error => { return error.message; })
    return response;
}

const columns = [
    { field: 'id', headerName: 'id', width: 120 },
];

const rows = [
    { id: "n/a" },
];

export default function FrameworkVersionControlList({ frameworkId, frameworkVersionId }) {
    //axios error message
    const [errMsg, setErrMsg] = useState("");

    const [rowsData, setRowsData] = useState([]);
    const [colsData, setColsData] = useState(null);

    const [loading, setLoading] = useState(false);

    const [controlsListData, setControlsListData] = useState(null);

    const [openCreateDialog, setOpenCreateDialog] = useState(null);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(null);
    const [openCreateControlExistDialog, setOpenCreateControlExistDialog] = useState(null);

    const [editObj, setEditObj] = useState(null);

    const [selected, setSelected] = useState([]);

    const [isSystemAdmin, setIsSystemAdmin] = useState(false);

    const [frameworkVersionName, setFrameworkVersionName] = useState();
    const [frameworkName, setFrameworkName] = useState();

    useEffect(() => {
        if (localStorage.getItem("system_role") === "4" ||
            localStorage.getItem("system_role") === "1"
        ) {
            setIsSystemAdmin(true)
        }

    }, [setIsSystemAdmin])

    const handleClose = () => {
        setOpenCreateDialog(false);
        setOpenDeleteDialog(false);
        setOpenCreateControlExistDialog(false);
        setEditObj(null);
    }

    async function deleteSelectedRow() {
        if (rowsData?.length > 0) {
            var filteredArray = [...rowsData]
            for (let x = 0; x < selected.length; x++) {
                const response = await deleteData(selected[x]);
                if (response?.status !== 200) {
                    setErrMsg(`${response}`);
                }
                //setErrMsg(``)
                filteredArray = [...filteredArray.filter((row) => row.id !== selected[x])]
            }
            setRowsData(filteredArray);
        }

    };

    const onRowsSelectionHandler = (ids) => {
        setSelected([...ids]);
    };

    const refreshRows = async (rows) => {
        setRowsData(rows)
    }

    const getData = useCallback(async () => {
        setLoading(true)
        var response = await loadData(frameworkVersionId);

        if (!response?.data) {
            setErrMsg(`No data found`)
        }
        setControlsListData(response)
        if (response?.data) {
            var filteredRowData = [];
            setFrameworkName(response.data.framework.name)
            setFrameworkVersionName(response.data.version_prefix + " " + response.data.framework.name + " " + response.data.version_suffix)
            response.data.controls.forEach((key) => {
                filteredRowData.push({ id: key.id, name: key.name, description: key.description });
            })
            var colsVals = [
                {
                    field: 'name',
                    headerName: 'Name',
                    width: 600,
                    renderCell: (params) => (
                        <Link href={`/frameworks/${frameworkId}/framework_versions/${frameworkVersionId}/controls/${params.row.id}`}>{params.value}</Link>
                    )
                },
                {
                    field: 'description',
                    headerName: 'Description',
                    width: 600,
                    cellClass: "normalLineHeight"
                },
            ];
            setRowsData([...filteredRowData])
            setColsData(colsVals)
        }
        setLoading(false)
    }, [frameworkId, frameworkVersionId])

    // const addControlData = async (data) => {
    //     if (data) {
    //         await getData();
    //     } else {
    //         var arrcopy = rowsData.filter(row => row.id !== data.id)
    //         var tmparray = [...arrcopy, data];
    //         setRowsData(tmparray);
    //         getData();
    //     }
    //     handleClose();

    //     //editObj = null
    // }

    const isOpen = () => {
        setEditObj(null);
        getData()
        handleClose();
    }

    useEffect(() => {
        if (controlsListData === null) {
            getData();
        }
    }, [getData, controlsListData]);

    return (<>
        <div style={{ marginTop: '60px' }} className="control list">
            <>
                {errMsg ? (
                    <Stack sx={{ width: '100%' }} spacing={2} className="alerts">
                        <Alert severity="error">{errMsg}</Alert>
                    </Stack>
                ) : (
                    <></>
                )}
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/system-administration">
                        System Administration
                    </Link>
                    <Link underline="hover" color="inherit" href="/frameworks/list">
                        Frameworks
                    </Link>
                    <Link underline="hover" color="inherit" href={`/frameworks/${frameworkId}`}>
                        {frameworkName ? frameworkName : ""}
                    </Link>
                    <Link underline="hover" color="inherit" href={`/frameworks/${frameworkId}/framework_versions/${frameworkVersionId}`}>
                        {frameworkVersionName ? frameworkVersionName : ""}
                    </Link>
                    <Typography color="text.primary">Controls</Typography>
                </Breadcrumbs>
                <div className="control list" style={{ height: '600px', width: '100%' }}>
                    {isSystemAdmin && <>
                        <Button
                            variant="contained"
                            className="secondary btn"
                            startIcon={<DeleteIcon />}
                            disabled={!selected.length}
                            onClick={() =>
                                selected.length ? setOpenDeleteDialog(true) : null
                            }
                        >
                            Delete
                        </Button>
                        <Button
                            startIcon={<AddIcon />}
                            variant="outlined"
                            onClick={() => { setOpenCreateDialog(true); }}
                        >
                            Create
                        </Button>
                        <Button
                            startIcon={<AddIcon />}
                            variant="outlined"
                            onClick={() => { setOpenCreateControlExistDialog(true); }}
                        >
                            Add Existing
                        </Button>
                    </>}

                    <DataGrid
                        getRowClassName={(params) =>
                            params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'
                        }
                        rows={rowsData ? rowsData : rows}
                        columns={colsData ? colsData : columns}
                        rowsPerPageOptions={[10, 25, 50, 100]}
                        pageSize={10}
                        checkboxSelection
                        loading={loading}
                        components={{
                            LoadingOverlay: SpinnerComponent,
                        }}
                        onSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
                        getRowHeight={() => 'auto'}
                    />
                </div>
            </>
        </div>
        <Dialog
            //fullScreen
            open={openCreateDialog}
            onClose={handleClose}
            aria-labelledby="create-data"
            aria-describedby="alert-dialog-create-data"
        >
            <DialogActions className="create-edit-dialog">
                <Button onClick={handleClose}>Close</Button>
            </DialogActions>
            <DialogTitle id="alert-dialog-title">
                {editObj ? `Edit control` : `Create control`}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <CreateEdit editDataObj={editObj} dataType="controls" />
                </DialogContentText>
            </DialogContent>
        </Dialog>
        <Dialog
            open={openDeleteDialog}
            onClose={handleClose}
            aria-labelledby="delete-confirm"
            aria-describedby="alert-dialog-delete-confirm"
        >
            <DialogTitle id="alert-dialog-title">
                {"Delete?"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Are you sure you want to delete this framework version?
                </DialogContentText>
            </DialogContent>
            <DialogActions className="create-edit-dialog">
                <Button onClick={handleClose}>Close</Button>
                <Button onClick={() => { handleClose(); deleteSelectedRow(); }} autoFocus>
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
        <Dialog
            //fullScreen
            open={openCreateControlExistDialog}
            onClose={handleClose}
            aria-labelledby="create-data"
            aria-describedby="alert-dialog-create-data"
        >
            <DialogActions className="create-edit-dialog">
                <Button onClick={handleClose}>Close</Button>
            </DialogActions>
            <DialogTitle id="alert-dialog-title">
                Add Existing Controls
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <MultipleControlSelect isOpen={isOpen} frameworkVersId={frameworkVersionId} rowsReturnData={refreshRows} />
                </DialogContentText>
            </DialogContent>
        </Dialog>

    </>
    )

}