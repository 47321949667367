import React, { useEffect, useState } from "react";
import '../css/Register.css';
import Button from '@mui/material/Button';
import TextField from "@mui/material/TextField"
import Box from '@mui/material/Box';
import Loader from './Loader';
import axios from 'axios';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Logo from '../assets/fedrisk-full-logo.png';


export default function RegisterOTP({ registration, is_user }) {

  const [errMsg, setErrMsg] = useState("");

  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
  }, [otp]);

  useEffect(() => {
  }, [errMsg])

  const resendCode = async () => {
    const resp = await resendOTP();
    if (resp === 'Request failed with status code 404') {
      alert('Your code could not be sent')
      setErrMsg('Your code could not be sent')
    }
  }

  const resendOTP = async () => {
    var method = "POST";
    var url = `${process.env.REACT_APP_API_URL}/tenants/resend-cognito-confirmation-code`
    const response = axios(url, {
      method: method,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.access_token}`,
      },
      data: {
        "client_id": registration["client_id"],
        "username": registration["cognito_user"],
      },
    }).then(result => {
      return result;
    }).catch(error => { return error.message; })
    return response;
  }

  const cognitoConfirmSignup = async () => {
    var method = "POST";
    var url = `${process.env.REACT_APP_API_URL}/tenants/cognito-confirm-signup`
    const response = axios(url, {
      method: method,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        "client_id": registration["client_id"],
        "username": registration["cognito_user"],
        "confirmation_code": otp
      },
    }).then(result => {
      return result;
    }).catch(error => { return error.message; })
    return response;
  }

  async function createCustomer(access) {
    var url = `${process.env.REACT_APP_API_URL}/subscriptions/create_customer`;
    var method = "POST";
    const response = axios(url, {
      method: method,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${access}`,
      },
    }).then(result => {
      return result;
    }).catch(error => { return error.message; })
    return response;
  }

  const handleSubmit = async e => {
    setLoading(true);
    e.preventDefault();
    //submit cognito confirmation code
    const tryconfirm = await cognitoConfirmSignup();
    if (tryconfirm) {
      //create customer using access token
      const createTenantCustomer = await createCustomer(registration.access_token);
      localStorage.setItem("access_token", registration.access_token);
      localStorage.setItem("customer_id", createTenantCustomer.data.customer_id);
      localStorage.setItem("user", registration.user)
      localStorage.setItem("tenant_id", registration.user.tenant.id)
      //if (createTenantCustomer.status === `200`) {

     // }
      alert(`Your registration was successful!`)
      if (is_user) {
        window.location.replace(`/`);
      } else {
        window.location.replace(`/select-plan`);
      }
     
      setLoading(false);
    }
    else {
      alert(`Your registration was not successful...`)
    }
    //window.location.replace("/");
    setLoading(false);

  }

  if (loading) {
    return (
      <Loader />
    )
  }

  return (

    <div className="register-wrapper otp">
      {errMsg ? (
        <Stack sx={{ width: '100%' }} spacing={2} className="alerts">
          <Alert severity="error">{errMsg}</Alert>
        </Stack>
      ) : (
        <></>
      )}
      <Box
        sx={{
          '& .MuiTextField-root': { m: 1, width: '100%' },
        }}
      >
        <img src={Logo} alt="Logo" width="481px" />
        <h1>Enter Confirmation Code</h1>
        <p>Please check your email for a confirmation code. If it does not arrive in five minutes please click the re-send button below.</p>
        <form onSubmit={handleSubmit}>
          <div><TextField required id="otp" value={otp} label="Confirmation code" onChange={e => setOtp(e.target.value)}
            style={{
              width: 500
            }} /></div>
          <div>
            <Button className="sign-in" type="submit" variant="contained">Continue</Button>
          </div>
          <div>
          </div>
        </form>
        <Button className="re-send" variant="contained" onClick={resendCode}>Resend Confirmation Code</Button>
        <div className="login-here">Already have an account? <a href="/">Log In</a></div>
      </Box>
    </div>
  )
}