/* If a user is on a trial this component displays a message */
import React, { useState, useEffect, useCallback } from 'react';
import axios from "axios";
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { fromUnixTime, getTime } from 'date-fns'

export default function CancelMessage({ canceled, paused }) {
    const [customerId, setCustomerId] = useState('');
    const [notActive, setNotActive] = useState(false);
    const [isPaused, setIsPaused] = useState(false);

    useEffect(() => {
        getCustomerId();
    }, [customerId]);

    useEffect(() => {
        if (notActive === true && window.location.pathname !== '/subscription'){
            window.location.replace('/subscription')
        }
        
    }, [notActive])

    useEffect(() => {
        if (isPaused === true && window.location.pathname !== '/subscription'){
            window.location.replace('/subscription')
        }
        
    }, [isPaused])

    const getCustomerId = async () => {
        var url = `${process.env.REACT_APP_API_URL}/tenants/customer_id`;
        const response = await axios(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.access_token}`,
            },
        }).then(result => {
            setCustomerId(result.data);
            return result;
        }).catch(error => { return error.message; })
        return response;
    };

    const getActiveSubscription = useCallback(async () => {
        var url = `${process.env.REACT_APP_API_URL}/subscriptions/list`;
        var body = JSON.stringify(
            {
                customer: customerId,
                status: "active",
            })
        await axios(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.access_token}`,
            },
            data: body,
        }).then(result => {
            if (result.data.data.length > 0 && result.data.data[0].pause_collection) {
                if (result.data.data[0].pause_collection.resumes_at) {
                    paused(true)
                    setIsPaused(true)
                    
                    let endtime = fromUnixTime(result.data.data[0].pause_collection.resumes_at)
                    let now = getTime(new Date())
                    if (endtime < now) {
                        setNotActive(true)
                    }
                }
            }
            return result;
        }).catch(error => { return error.message; })
    }, [customerId, paused])

    useEffect(() => {
        if (customerId) {
            getActiveSubscription()
        }
    }, [customerId, getActiveSubscription])

    if (notActive) {
        return (
            <Stack sx={{ width: '100%' }} spacing={2} className="alerts">
                <Alert severity="warning">Your subscription was cancelled. To re-start make sure you have a valid payment method and select the 'Upgrade Plan' button.</Alert>
            </Stack>
    
        )
    }
    if (isPaused) {
        return (
            <Stack sx={{ width: '100%' }} spacing={2} className="alerts">
                <Alert severity="warning">Your subscription is currently paused. To re-start make sure you have a valid payment method and select the 'Upgrade Plan' button.</Alert>
            </Stack>
    
        )
    }
    return (
        <></>
    )
    


}
