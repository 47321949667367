import React, { useEffect, useState, useCallback, useMemo } from "react";
import TextField from "@mui/material/TextField";
import Button from '@mui/material/Button';
import { MenuItem, Select } from '@mui/material';
import { useForm, Controller } from "react-hook-form";
import axios from 'axios';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import DatePicker from "react-multi-date-picker";

/* This class is for creating and editing an audit test on a project */

export async function getKeywords() {
  const url = `${process.env.REACT_APP_API_URL}/keyword/`
  const response = axios(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${localStorage.access_token}`,
    },
  }).then(result => {
    return result;
  }).catch(error => { return error.message; })
  return response;
}

export async function getDropdownData(projectId) {
  var url = `${process.env.REACT_APP_API_URL}/projects/${projectId}`;

  const response = axios(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${localStorage.access_token}`,
    },
  }).then(result => {
    return result;
  }).catch(error => { return error.message; })
  return response;
}

export async function getUsers() {
  const url = `${process.env.REACT_APP_API_URL}/users/get_users_tenant`
  const response = axios(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${localStorage.access_token}`,
    },
  }).then(result => {
    return result;
  }).catch(error => { return error.message; })
  return response;
}

export default function CreateEditProjectAuditTest({ editDataObj, projectId, isOpen, controlData }) {
  //console.log('editDataObj', editDataObj)
  //axios error message
  const [errMsg, setErrMsg] = useState("");
  useEffect(() => {
  }, [errMsg])

  //used with users
  const [users, setUsers] = useState([]);

  //used with select fields in form
  const [projectControls, setProjectControls] = useState([]);
  const [projectControlId, setProjectControlId] = useState(editDataObj?.project_control?.id);
  const [testerId, setTesterId] = useState(editDataObj?.tester?.id);
  const [testStakeholderId, setTestStakeholderId] = useState(editDataObj?.stakeholder?.id);
  const [testStakeholders, setTestStakeholders] = useState([]);
  const [testFrequencyId, setTestFrequencyId] = useState(editDataObj?.test_frequency);
  const [testStatusId, setTestStatusId] = useState(editDataObj?.status);

  //default option when no value selected in drop down
  const selectOption = useMemo(() => { return { value: "0", label: "Please select..." } }, [])
  const userSelectOption = useMemo(() => { return { id: 0, email: "Please select..." } }, [])
  const [keywords, setKeywords] = useState([{ id: "0", name: "Please select..." }]);;

  const [keywordSelections, setKeywordSelections] = useState([]);

  const [keywordId, setKeywordId] = useState();
  const keywordSelectOption = useMemo(() => { return { id: "0", name: "Please select..." } }, [])

  useEffect(() => {
    if (editDataObj?.keywords) {
      let options = []
      for (let i = 0; i < editDataObj?.keywords.length; i++) {
        options.push({ id: editDataObj?.keywords[i].id, name: editDataObj?.keywords[i].keyword.name })
      }
      setKeywordSelections(options)
    }
  }, [editDataObj])

  const removeKeyword = (key) => {
    var tmpfilter = keywordSelections.filter(keyword => keyword.id !== key.id)
    setKeywordSelections(tmpfilter);
    var keyword = keywordSelections.filter(word => word.id === key.id)
    var name = keyword[0].name
    setKeywords(keywords => [...keywords, { name: name, id: key.id }]);
  }

  const handleChangeKeywordSelect = (event) => {
    setKeywordId(event.target.value);
    var keyword = keywords.filter(word => word.id === event.target.value)
    var name = keyword[0].name
    setKeywordSelections(keywordSelections => [...keywordSelections, { name: name, id: event.target.value }]);
    var tmpfilterSelect = keywords.filter(keyword => keyword.id !== event.target.value)
    setKeywords(tmpfilterSelect)
  }

  const testFrequency = [
    selectOption,
    { value: "Daily", label: "Daily" },
    { value: "Weekly", label: "Weekly" },
    { value: "Monthly", label: "Monthly" },
    { value: "Quarterly", label: "Quarterly" },
    { value: "Annually", label: "Annually" },
  ]
  const testStatus = [
    selectOption,
    { value: "Not Started", label: "Not Started" },
    { value: "On Going", label: "On Going" },
    { value: "Complete", label: "Complete" },
    { value: "On Hold", label: "On Hold" },
  ]

  const { control, handleSubmit } = useForm({
    defaultValues: {
      name: editDataObj?.name,
      description: editDataObj?.description,
      comments: editDataObj?.comments,
      objective: editDataObj?.objective,
      externalid: editDataObj?.external_reference_id,
      lastTestDate: editDataObj?.last_test_date,
      startDate: editDataObj?.start_date,
      approximateTimeToComplete: editDataObj?.approximate_time_to_complete,
      projectControlSelect: {},
      dueDate: editDataObj?.due_date,
      testFrequencySelect: {},
      testStatusSelect: {},
      testExpectedResults: editDataObj?.expected_results,
      testStakeholders: {},
      auditTestStakeholdersSelect: {},
      auditTesterSelect: {},
      newKeywords: "",
      keywordSelect: {},
    }
  });

  // handle change events on dropdowns
  const handleChangeProjectControlSelect = (event) => {
    setProjectControlId(event.target.value);
  }

  const handleChangeTesterSelect = (event) => {
    setTesterId(event.target.value);
  }
  const handleChangeTestFrequencySelect = (event) => {
    setTestFrequencyId(event.target.value);
  }
  const handleChangeTestStatusSelect = (event) => {
    setTestStatusId(event.target.value);
  }

  const handleChangeTestStakeholderSelect = (event) => {
    setTestStakeholderId(event.target.value);
    var useremail = users.filter(user => user.id === event.target.value)
    var email = useremail[0].email
    setTestStakeholders(testStakeholders => [...testStakeholders, { email: email, id: event.target.value }]);
  }

  const removeStakeholder = (stake) => {
    var tmpfilter = testStakeholders.filter(stakeholder => stake.id !== stakeholder.id)
    setTestStakeholders(tmpfilter);
  }

  const convertToSendDate = (date) => {
    return `${new Date(date).toISOString().split("T")[0]}`;
  };

  /* form submit */
  const onSubmit = async (data) => {
    const response = await postFormData(data);
    if (response.status === 200) {
      controlData(response.data)
      isOpen(false);
    } else {
      setErrMsg(`${response}`)
    }
  }

  async function postFormData(data) {
    var cleanedWords = ""
    //look at keywords
    if (!/^[a-z,]*$/.test(data?.newKeywords)) {
      setErrMsg(`Sorry your keywords contain values other than lowercase characters and commas`)
    } else {
      //clean up duplicate vals
      var cleanedKeywords = []
      var enteredKeywords = data.newKeywords.split(',')
      for (let x = 0; x < enteredKeywords.length; x++) {
        let match = false
        for (let y = 0; y < keywordSelections.length; y++) {
          if (enteredKeywords[x] === keywordSelections[y].name) {
            match = true
          }
        }
        if (match === false) {
          cleanedKeywords.push(enteredKeywords[x])
        }
      }
      for (let p = 0; p < keywordSelections.length; p++) {
        cleanedKeywords.push(keywordSelections[p].name)
      }
      cleanedWords = cleanedKeywords.join(',')
    }
    var url;
    var body;
    var method = "POST";
    var stakeholderidarr = [];
    for (let i = 0; i < testStakeholders.length; i++) {
      stakeholderidarr.push(testStakeholders[i].id)
    }
    /*if (editDataObj?.stakeholders) {
      for (let i= 0; i < editDataObj?.stakeholders?.length; i ++) {
        stakeholderidarr.push(parseInt(editDataObj.stakeholders[i].id))
      }
    }*/
    var removenullids = []
    for (let p = 0; p < stakeholderidarr.length; p++) {
      if (stakeholderidarr[p] !== null) {
        removenullids.push(parseInt(stakeholderidarr[p]))
      }
    }
    var currentDate = new Date()
    var lastTestDate = convertToSendDate(currentDate);
    var dueDate = convertToSendDate(currentDate);
    var startDate = convertToSendDate(currentDate);
    //format timestamps to date string
    if (data.lastTestDate) {
      lastTestDate = convertToSendDate(data.lastTestDate)
    }
    if (data.dueDate) {
      dueDate = convertToSendDate(data.dueDate)
    }
    if (data.startDate) {
      startDate = convertToSendDate(data.startDate)
    }

    /* project audit_tests */
    url = `${process.env.REACT_APP_API_URL}/audit_tests?keywords=${cleanedWords}`
    body = JSON.stringify({
      project_id: projectId,
      project_control_id: projectControlId,
      name: data.name,
      description: data.description,
      tester_id: testerId,
      stakeholder_ids: removenullids,
      test_frequency: testFrequencyId,
      //external_reference_id: data.externalid,
      external_reference_id: "0",
      objective: data.objective,
      approximate_time_to_complete: data.approximateTimeToComplete,
      expected_results: data.testExpectedResults,
      last_test_date: lastTestDate,
      due_date: dueDate,
      start_date: startDate,
      status: testStatusId,
    })
    //if a data object is being edited
    if (editDataObj) {
      method = "PUT";
      url = `${process.env.REACT_APP_API_URL}/audit_tests/${editDataObj.id}?keywords=${cleanedWords}`;
    }

    const response = axios(url, {
      method: method,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.access_token}`,
      },
      data: body,
    }).then(result => {
      return result;
    }).catch(error => { return error.message; })
    return response;
  }


  const getDropDownOptions = useCallback(async () => {
    const jsonDataKey = await getKeywords();
    if (jsonDataKey?.data){
      const tmpoptionskeys = jsonDataKey.data.map((word) => ({
        id: word.id,
        name: word.name,
      }));
      var allOptions = [keywordSelectOption, ...tmpoptionskeys];
      setKeywords(allOptions);
    }
    const jsonData = await getDropdownData(projectId);
    if (jsonData?.data) {
      const projectControlOptions = jsonData.data.project_controls.map((control) => ({
        value: control.id,
        label: control.control.name,
      }));
      var allControlOptions = [selectOption, ...projectControlOptions];
      setProjectControls(allControlOptions);
    }
    const jsonDataUsers = await getUsers();
    if (jsonDataUsers?.data) {
      const tmpoptions = jsonDataUsers.data.map((user) => ({
        id: user.id,
        email: user.email,
      }));
      var allUserOptions = [userSelectOption, ...tmpoptions];
      setUsers(allUserOptions);
    }

  }, [projectId, selectOption, userSelectOption, keywordSelectOption])



  useEffect(() => {
    getDropDownOptions();
  }, [getDropDownOptions]);

  useEffect(() => {
    if (editDataObj?.stakeholders) {
      setTestStakeholders(editDataObj?.stakeholders)
    }
  }, [editDataObj])

  useEffect(() => {

  }, [testStakeholderId, testStakeholders]);

  return (
    <div className="create edit project audit test">
      {errMsg ? (
        <Stack sx={{ width: '100%' }} spacing={2} className="alerts">
          <Alert severity="error">{errMsg}</Alert>
        </Stack>
      ) : (
        <></>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-field name">
          <Controller
            name="name"
            control={control}
            render={({ field }) => <TextField required label="Name" {...field} fullWidth />}
          />
        </div>

        <div className="form-field description">
          <Controller
            name="description"
            control={control}
            render={({ field }) =>
              <TextField
                {...field}
                multiline
                rows={4}
                label="Description"
                fullWidth
              />
            }
          />
        </div>
        <div className="form-field external-id">
          <Controller
            name="externalid"
            control={control}
            render={({ field }) => <TextField label="External Reference ID" {...field} fullWidth />}
          />
        </div>
        <div className="form-field objective">
          <Controller
            name="objective"
            control={control}
            render={({ field }) =>
              <TextField
                {...field}
                multiline
                rows={4}
                label="Objective"
                fullWidth
              />
            }
          />
        </div>
        <div className="form-field expected">
          <Controller
            name="testExpectedResults"
            control={control}
            render={({ field }) =>
              <TextField
                {...field}
                multiline
                rows={4}
                label="Expected Results"
                fullWidth
              />
            }
          />
        </div>
        <div className="form-field control">
          <label>Project Control</label>
          <Controller
            name="projectControlSelect"
            control={control}
            defaultValue={0}
            render={({ field }) =>
              <Select
                {...field}
                onChange={handleChangeProjectControlSelect}
                value={projectControlId ? projectControlId : 0}
                defaultValue={0}
                inputProps={{ "data-testid": "project-control" }}
              >
                {
                  projectControls?.map((control) => {
                    return <MenuItem
                      value={control.value}
                      key={control.value}>
                      {control.label}
                    </MenuItem>
                  })
                }
              </Select>
            }
          />
        </div>
        <div className="form-field control">
          <label>Test Frequency</label>
          <Controller
            name="testFrequencySelect"
            control={control}
            defaultValue={0}
            render={({ field }) =>
              <Select
                {...field}
                value={testFrequencyId ? testFrequencyId : 0}
                onChange={handleChangeTestFrequencySelect}
                defaultValue={0}
                inputProps={{ "data-testid": "test-frequency" }}
              >
                {
                  testFrequency?.map((tf) => {
                    return <MenuItem
                      value={tf.value}
                      key={tf.value}>
                      {tf.label}
                    </MenuItem>
                  })
                }
              </Select>
            }
          />
        </div>
        <div className="form-field control">
          <label>Test Status</label>
          <Controller
            name="testStatusSelect"
            control={control}
            defaultValue={0}
            render={({ field }) =>
              <Select
                {...field}
                onChange={handleChangeTestStatusSelect}
                defaultValue={0}
                value={testStatusId ? testStatusId : 0}
                inputProps={{ "data-testid": "test-status" }}
              >
                {
                  testStatus?.map((ts) => {
                    return <MenuItem
                      value={ts.value}
                      key={ts.value}>
                      {ts.label}
                    </MenuItem>
                  })
                }
              </Select>
            }
          />
        </div>
        <div className="form-field control">
          <label>Tester</label>
          <Controller
            name="auditTesterSelect"
            control={control}
            defaultValue={0}
            render={({ field }) =>
              <Select
                {...field}
                onChange={handleChangeTesterSelect}
                defaultValue={0}
                value={testerId ? testerId : 0}
                inputProps={{ "data-testid": "tester" }}
              >
                {
                  users?.map((user) => {
                    return <MenuItem
                      value={user.id}
                      key={user.id}>
                      {user.email}
                    </MenuItem>
                  })
                }
              </Select>
            }
          />
        </div>
        <div className="form-field control">
          <label>Stakeholders</label>
          <Controller
            name="auditTestStakeholdersSelect"
            control={control}
            defaultValue={0}
            render={({ field }) =>
              <Select
                {...field}
                value={testStakeholderId ? testStakeholderId : 0}
                defaultValue={0}
                onChange={handleChangeTestStakeholderSelect}
                inputProps={{ "data-testid": "stakeholder" }}
              >
                {
                  users?.map((user) => {
                    return <MenuItem
                      value={user.id}
                      key={user.id}>
                      {user.email}
                    </MenuItem>
                  })
                }
              </Select>
            }
          />
        </div>
        <ul>
          {(testStakeholders && (
            testStakeholders.map((stakeholder) => {
              return <li>
                {stakeholder.email}
                <IconButton aria-label="delete">
                  <DeleteIcon onClick={() => { removeStakeholder(stakeholder); }} />
                </IconButton>
              </li>
            })
          )
          )}
        </ul>
        <div className="form-field date">
          <label>Last Test Date</label>
          <Controller
            control={control}
            name="lastTestDate"
            render={({
              field: { onChange, name, value },
              //fieldState: { invalid, isDirty }, //optional
            }) => (
              <>
                <DatePicker
                  value={value || ""}
                  onChange={(date) => {
                    onChange(date?.isValid ? date : "");
                  }}
                />

              </>
            )}
          />
        </div>
        <div className="form-field date">
          <label>Start Date</label>
          <Controller
            control={control}
            name="startDate"
            rules={{ required: true }} //optional
            render={({
              field: { onChange, name, value },
              //fieldState: { invalid, isDirty }, //optional
              formState: { errors }, //optional, but necessary if you want to show an error message
            }) => (
              <>
                <DatePicker
                  value={value || ""}
                  onChange={(date) => {
                    onChange(date?.isValid ? date : "");
                  }}
                />
                {errors && errors[name] && errors[name].type === "required" && (
                  //if you want to show an error message
                  <span>The start date is required!</span>
                )}
              </>
            )}
          />
        </div>
        <div className="form-field date">
          <label>Due Date</label>
          <Controller
            control={control}
            name="dueDate"
            rules={{ required: true }} //optional
            render={({
              field: { onChange, name, value },
              //fieldState: { invalid, isDirty }, //optional
              formState: { errors }, //optional, but necessary if you want to show an error message
            }) => (
              <>
                <DatePicker
                  value={value || ""}
                  onChange={(date) => {
                    onChange(date?.isValid ? date : "");
                  }}
                />
                {errors && errors[name] && errors[name].type === "required" && (
                  //if you want to show an error message
                  <span>The due date is required!</span>
                )}
              </>
            )}
          />
        </div>
        <div className="form-field complete">
          <Controller
            name="approximateTimeToComplete"
            control={control}
            render={({ field }) => <TextField label="Approximate Time To Complete" {...field} fullWidth />}
          />
        </div>
        <div className="form-field keywords">
          <div className="form-field control">
            <label>Existing Keywords</label>
            <Controller
              name="keywordSelect"
              control={control}
              defaultValue={0}
              render={({ field }) =>
                <Select
                  {...field}
                  value={keywordId ? keywordId : 0}
                  defaultValue={0}
                  onChange={handleChangeKeywordSelect}
                  inputProps={{ "data-testid": "keyword" }}
                >
                  {
                    keywords?.map((word) => {
                      return <MenuItem
                        value={word.id}
                        key={word.id}>
                        {word.name}
                      </MenuItem>
                    })
                  }
                </Select>
              }
            />
          </div>
          <ul>
            {(keywordSelections && (
              keywordSelections.map((word) => {
                return <li>
                  {word.name}
                  <IconButton aria-label="delete">
                    <DeleteIcon onClick={() => { removeKeyword(word); }} />
                  </IconButton>
                </li>
              })
            )
            )}
          </ul>
          <div className="form-field newkeywords">
            <Controller
              name="newKeywords"
              control={control}
              render={({ field }) =>
                <TextField
                  {...field}
                  label="Add new keywords"
                  //fullWidth
                  helperText="Enter your keyword in all lowercase with a comma and no spaces between each one e.g. legal,technical"
                />

              }
            />
          </div>
        </div>
        <div>
          {
            (editDataObj && <Button type="submit" variant="contained" >Submit</Button>) ||
            (!editDataObj && <Button type="submit" variant="contained" >Create</Button>)
          }
        </div>
      </form>
    </div>
  )
}