import React, { useEffect, useState, useCallback } from "react";
import { DataGrid } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Link from '@mui/material/Link';
import SpinnerComponent from '../LoadingSpinner';
import axios from 'axios';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import EditIcon from '@mui/icons-material/Edit';
import CreateEditDocument from "../CreateEditDocument";
import AddCircleIcon from '@mui/icons-material/AddCircle';

//This class displays lists of documents

export async function getRefreshedData(dataobj) {
    //call data endpoint for data type to set rows and columns
    var url = `${process.env.REACT_APP_API_URL}/${dataobj.fedrisk_object_type}/${dataobj.fedrisk_object_id}`;
    const response = axios(url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.access_token}`,
        },
    }).then(result => {
        return result;
    }).catch(error => { return error.message; })

    return response;
}

export async function deleteData(id) {
    var url = `${process.env.REACT_APP_API_URL}/documents/${id}`;

    const response = axios(url, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.access_token}`,
        },
    }).then(result => {
        return result;
    }).catch(error => { return error.message; })
    return response;
}


const columns = [
    { field: 'id', headerName: 'id', width: 120 },
];

const rows = [
    { id: "n/a" },
];

export default function DocumentList({
    projectId,
    documentListData,
    dataType,
    dataId,
}) {

    useEffect(() => {
        // console.log('documentListData', documentListData)
        // console.log('dataId', dataId)
        // console.log('dataType', dataType)
    }, [documentListData, dataId, dataType])
    //axios error message
    const [errMsg, setErrMsg] = useState("");

    const [rowsData, setRowsData] = useState([]);
    const [colsData, setColsData] = useState(null);

    const [editObj, setEditObj] = useState(null);

    const [loading, setLoading] = useState(false);

    const [openAddDialog, setOpenAddDialog] = useState(null);

    const [selected, setSelected] = useState([]);

    const [openDialog, setOpenDialog] = useState(null);

    const onRowsSelectionHandler = (ids) => {
        setSelected([...ids]);
    };

    const handleClose = () => {
        setOpenAddDialog(false);
        setOpenDialog(false);
        setEditObj(null);
    }

    const isOpen = () => {
        //setEditObj(null);
        handleClose();
    }

    const refreshData = async (dataobj) => {
        const jsonData = await getRefreshedData(dataobj);
        if (jsonData.data) {
            var filteredRowDocuments = [];
            jsonData?.data?.documents?.forEach((key) => {
                filteredRowDocuments.push({
                    id: key.document.id,
                    name: key.document.name,
                    title: key.document.title,
                    description: key.document.description,
                    keywords: key.document.keywords,
                    owner_id: key.document.owner_id,
                    version: key.document.version,
                });
            })
            setRowsData([...filteredRowDocuments]);
            var colsVals = [
                {
                    field: 'title',
                    headerName: 'Title',
                    width: 150,
                    renderCell: (params) => (
                        <Link href={`/documents/${params.row.id}`}>{params.value}</Link>
                    )
                },
                {
                    field: 'action',
                    headerName: 'Action',
                    width: 200,
                    renderCell: (params) => (
                        <>
                            <Button startIcon={<EditIcon />} onClick={() => { setEditObj(params.row); setOpenAddDialog(true) }} />
                            <Button startIcon={<DeleteIcon />} onClick={() => { setEditObj(params.row); setOpenDialog(true) }} />
                        </>
                    )

                },
            ];
            setColsData([...colsVals]);
        }
    }

    const addControlData = (data) => {
        refreshData(data)
    }

    async function deleteSelectedRow() {
        if (editObj) {
            const response = await deleteData(editObj.id);
            if (response?.status !== 200) {
                setErrMsg(`${response}`);
            }
            if (response?.status === 200) {
                let filteredArray = rowsData?.filter(item => item.id !== editObj.id)
                setRowsData(filteredArray);
            }
        }
        if (selected.length !== 0 && !editObj) {
            for (let x = 0; x < selected.length; x++) {
                const response = await deleteData(selected[x]);
                if (response?.status !== 200) {
                    setErrMsg(`${response}`);
                }
                if (response?.status === 200) {
                    let filteredArray = rowsData?.filter(item => item.id !== selected[x])
                    setRowsData(filteredArray);
                }
            }
        }

    };

    const getData = useCallback(async() => {
        setLoading(true)
        if (documentListData) {
            var filteredRowDocuments = [];
            documentListData?.forEach((key) => {
                filteredRowDocuments.push({
                    id: key.document.id,
                    name: key.document.name,
                    title: key.document.title,
                    description: key.document.description,
                    keywords: key.document.keywords,
                    owner_id: key.document.owner_id,
                    version: key.document.version,
                });
            })

            setRowsData([...filteredRowDocuments]);
            var colsVals = [
                {
                    field: 'title',
                    headerName: 'Title',
                    width: 150,
                    renderCell: (params) => (
                        <Link href={`/documents/${params.row.id}`}>{params.value}</Link>
                    )
                },
                {
                    field: 'action',
                    headerName: 'Action',
                    width: 200,
                    renderCell: (params) => (
                        <>
                            <Button startIcon={<EditIcon />} onClick={() => { setEditObj(params.row); setOpenAddDialog(true) }} />
                            <Button startIcon={<DeleteIcon />} onClick={() => { setEditObj(params.row); setOpenDialog(true) }} />
                        </>
                    )

                },
            ];
            setColsData([...colsVals]);
        }
        setLoading(false)
    }, [documentListData])

    useEffect(() => {
        if (rowsData.length === 0) {
            getData();
        }
        if (!documentListData || documentListData.length === 0) {
            setRowsData([])
            setColsData([])
        }
    }, [documentListData, getData, rowsData.length]);

    return (
        <div style={{ marginTop: '-40px' }} className="document list">
            <>
                {errMsg ? (
                    <Stack sx={{ width: '100%' }} spacing={2} className="alerts">
                        <Alert severity="error">{errMsg}</Alert>
                    </Stack>
                ) : (
                    <></>
                )}
                <Button
                    variant="contained"
                    className="secondary btn"
                    startIcon={<DeleteIcon />}
                    disabled={!selected.length}
                    onClick={() =>
                        selected.length ? setOpenDialog("delete") : null
                    }
                >
                    Delete
                </Button>
                <Button
                    variant="outlined"
                    startIcon={<AddCircleIcon />}
                    onClick={() => { setOpenAddDialog(true); }}
                    className="addBtn"
                >Add</Button>
                <div className="list" style={{ height: '600px', width: '100%' }}>
                    <DataGrid
                        getRowClassName={(params) =>
                            params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'
                        }
                        rows={rowsData ? rowsData : rows}
                        columns={colsData ? colsData : columns}
                        rowsPerPageOptions={[10, 25, 50, 100]}
                        pageSize={10}
                        checkboxSelection
                        loading={loading}
                        components={{
                            LoadingOverlay: SpinnerComponent,
                        }}
                        onSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
                        getRowHeight={() => 'auto'}
                    />
                </div>
                <Dialog
                    //fullScreen
                    open={openAddDialog ? openAddDialog : false}
                    onClose={handleClose}
                    aria-labelledby="add-project-data"
                    aria-describedby="alert-dialog-add-project-data"
                >
                    <DialogActions className="create-edit-dialog">
                        <Button onClick={handleClose}>Cancel</Button>
                    </DialogActions>
                    <DialogTitle id="alert-dialog-title">
                        {editObj ? `Edit Document` : `Add Document to ${dataType}`}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <CreateEditDocument isOpen={isOpen} editDataObj={editObj} controlData={addControlData} projectId={projectId} dataId={dataId} dataType={dataType} />
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
                <Dialog
                    open={openDialog ? openDialog : false}
                    onClose={handleClose}
                    aria-labelledby="delete-confirm"
                    aria-describedby="alert-dialog-delete-confirm"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Delete?"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to delete this?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions className="create-edit-dialog">
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button onClick={() => { handleClose(); deleteSelectedRow(); }} autoFocus>
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        </div>
    )

}