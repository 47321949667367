import React, { useState, useEffect } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
//import Button from '@mui/material/Button';
import SpinnerComponent from '../components/LoadingSpinner';
import axios from 'axios';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

/* This is a generic view for returning details on a data object */


export default function KeywordResults({ id }) {
    const [data, setData] = useState([]);

    const [isSystemAdmin, setIsSystemAdmin] = useState(false);
    useEffect(() => {
        if (localStorage.getItem("system_role") === "4" || localStorage.getItem("system_role") === "1" || localStorage.getItem("system_role") === "2") {
            setIsSystemAdmin(true)
        }
    }, [])

    useEffect(() => {
    }, [isSystemAdmin])

    //axios error message
    const [errMsg, setErrMsg] = useState("");
    useEffect(() => {
    }, [errMsg])

    async function loadData() {
        //call data endpoint for data type to set rows and columns
        var url = `${process.env.REACT_APP_API_URL}/keyword/${id}`;

        const response = axios(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.access_token}`,
            },
        }).then(result => {
            return result;
        }).catch(error => { return error.message; })
        return response;
    }

    async function getData() {
        const jsonData = await loadData();
        if (jsonData !== "Request failed with status code 404" && jsonData !== "Network Error") {
            setData(jsonData.data);
            console.log(jsonData.data)
        } else {
            setErrMsg(`${jsonData}`)
        }
    }

    useEffect(() => {
        if (data.length === 0) {
            getData();
        }

    });

    return (
        <>
            {errMsg ? (
                <Stack sx={{ width: '100%' }} spacing={2} className="alerts">
                    <Alert severity="error">{errMsg}</Alert>
                </Stack>
            ) : (
                <></>
            )}
            <div className="details-view keywords">
                {(!data && <SpinnerComponent />)}
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/">
                        Home
                    </Link>
                    <Typography color="text.primary">{data?.name}</Typography>
                </Breadcrumbs>
                <>
                    <h1>{data?.name ? data.name : <p>N/A</p>}</h1>
                    <h2>Assessments</h2>
                    {data?.assessments?.map((assessment) => {
                        return <p><Link href={`/projects/${assessment?.assessment?.project_control?.project?.id}/controls/${assessment?.assessment?.project_control?.id}/assessments/${assessment?.assessment?.id}`}>{assessment?.assessment?.name}</Link></p>
                    })}
                    <h2>Audit Tests</h2>
                    {data?.audit_tests?.map((at) => {
                        return <p><Link href={`/projects/${at?.audit_test?.project?.id}/audit_tests/${at?.audit_test?.id}`}>{at?.audit_test?.name}</Link></p>
                    })}
                    <h2>Controls</h2>
                    {data?.controls?.map((control) => {
                        return <p><Link href={`/controls/${control?.control?.id}`}>{control?.control?.name}</Link></p>
                    })}
                    <h2>Documents</h2>
                    {data?.documents?.map((document) => {
                        return <p><Link href={`/documents/${document?.document?.id}`}>{document?.document?.name}</Link></p>
                    })}
                    <h2>Frameworks</h2>
                    {data?.frameworks?.map((frame) => {
                        return <p><Link href={`/frameworks/${frame?.framework?.id}`}>{frame?.framework?.name}</Link></p>
                    })}
                    {/*<h2>Framework Versions</h2>
                    { data?.framework_versions?.map((fv) => {
                        if (fv.framework_version_id !== null){
                            return <p><Link href={`/framework_versions/${fv?.framework_version?.id}`}>{fv?.framework_version?.name}</Link></p>
                        }
                        
                    })} */}
                    <h2>Projects</h2>
                    {data?.projects?.map((project) => {
                        return <p><Link href={`/projects/${project?.project?.id}`}>{project?.project?.name}</Link></p>
                    })}
                    <h2>Project Controls</h2>
                    {data?.project_controls?.map((pc) => {
                        return <p><Link href={`/projects/${pc?.project?.id}/controls/${pc?.project_control_id}`}>{pc?.project_control?.control?.name}</Link></p>
                    })}
                    <h2>Project Evaluations</h2>
                    {data?.project_evaluations?.map((pe) => {
                        return <p><Link href={`/projects/${pe?.project_evaluation?.project?.id}/project_evaluations/${pe?.project_evaluation?.id}`}>{pe?.project_evaluation?.name}</Link></p>
                    })}
                    <h2>Risks</h2>
                    {data?.risks?.map((risk) => {
                        return <p><Link href={`/projects/${risk?.risk?.project?.id}/risks/${risk?.risk?.id}`}>{risk?.risk?.name}</Link></p>
                    })}
                    <h2>Tasks</h2>
                    {data?.tasks?.map((task) => {
                        return <p><Link href={`/projects/${task?.task?.project?.id}/tasks/${task?.task?.id}`}>{task?.task?.name}</Link></p>
                    })}
                    <h2>WBS</h2>
                    {data?.wbs?.map((w) => {
                        return <p><Link href={`/projects/${w?.wbs?.project?.id}/wbsstudio/${w?.wbs?.id}`}>{w?.wbs?.name}</Link></p>
                    })}
                </>
            </div>
        </>
    )
}