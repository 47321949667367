import React, { useState, useEffect, useCallback } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import axios from 'axios';
import '../css/TaskCalendar.css';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
//import List from './List';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import LoadingSpinner from './LoadingSpinner';
import WBSTaskList from './lists/WBSTaskList';
import { useLocation } from 'react-router-dom';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  bgcolor: 'background.paper',
  border: 'none',
  boxShadow: 24,
  p: 4,
};

export async function getTasksData (wbsId) {
  var url = "";
  if (!wbsId) {
    url = `${process.env.REACT_APP_API_URL}/tasks/?sort_by=priority`;
  }
  else {
    url = `${process.env.REACT_APP_API_URL}/tasks/wbs/${wbsId}`;
  }
  const response = axios(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${localStorage.access_token}`,
    },
  }).then(result => {
    return result;
  }).catch(error => { return error.message; })
  return response;
}

const TaskCalendar = () => {
  const location = useLocation();
  const [marks, setMarks] = useState(null);
  //const { setToastMessage } = useContext(SnackbarContext);
  const [openModal, setOpenModal] = useState(false);
  var [dueDate, setDueDate] = useState("");
  const [anchorEl, setAnchorEl] = useState(false);
  const [open, setOpen] = useState(false);
  const [popoverData, setPopoverData] = useState([]);
  const [loading, setLoading] = useState(false);
  const handleClose = () => setOpenModal(false);
  //axios error message
  const [errMsg, setErrMsg] = useState("");
  const wbsId = location.pathname.split("/")[4];
  const [taskData, setTaskData] = useState();

  function tileContent({ date, view }) {
    // Add class to tiles in month view only
    let currentValue =
      marks?.length &&
      marks?.find(
        (x) =>
          new Date(x.date).toLocaleDateString() ===
          new Date(date).toLocaleDateString()
      );

    let highestPriorityTasks =
      marks?.length &&
      marks?.filter(
        (x) =>
          new Date(x.date).toLocaleDateString() ===
          new Date(date).toLocaleDateString()
      );
    //setPopoverData(highestPriorityTasks)
    return view === "month" && currentValue ? (
      <div
        style={{
          fontSize: "1px",
          height: "1px",
          position: "relative",
          top: "-10px",
        }}
      >
        <Typography
          aria-owns={open ? "mouse-over-popover" : undefined}
          aria-haspopup="true"
          onMouseEnter={(e) => { handlePopoverOpen(e, date, highestPriorityTasks); }
          }
          onMouseLeave={handlePopoverClose}
          sx={{
            fontSize: "1px",
            height: "28px",
            position: "relative",
            top: "-10px",
            visibility: "none",
          }}
        >
          .
        </Typography>
      </div>
    ) : null;
  }

  const handlePopoverOpen = (event, date, data) => {
    setAnchorEl(event.currentTarget);
    setOpen(date.toLocaleDateString());
    setPopoverData(data);
    var tmpArr = []
    for (let i = 0; i < data.length; i++) {
      tmpArr.push(data[i])
    }
    setTaskData(tmpArr)
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setOpen(null);
    //setPopoverData([]);
  };

  const getData = useCallback(async() =>{
    setLoading(true);
    const jsonData = await getTasksData(wbsId);
    if (jsonData === 'Request failed with status code 401') {
      setErrMsg('Request failed with status code 401')
      localStorage.clear();
      window.location.reload(false);
    }
    if (jsonData.error) {
      //handle the error
      alert(`There was an error: ${JSON.stringify(jsonData)}`);
    }
    if (jsonData?.data) {
      let taskData = [];
      const reversed = jsonData?.data?.reverse();
      reversed.forEach(item => {
        taskData.push({
          date: new Date(`${item.due_date} 00:00:00`),
          priority: item.priority,
          name: item?.name,
          id: item?.id,
          description: item?.description,
          project_id: item?.project?.id,
          percent_complete: item?.percent_complete,
          due_date: item?.due_date,
          category: item?.category,
          status: item?.status,
          assigned_to: item?.assigned_to,
        });
        if (item.children) {
          item.children.forEach(it => {
            taskData.push({
              date: new Date(`${it.due_date} 00:00:00`),
              priority: it.priority,
              name: it?.name,
              id: it?.id,
              description: it?.description,
              project_id: it?.project?.id,
              percent_complete: item?.percent_complete,
              due_date: item?.due_date,
              category: item?.category,
              status: item?.status,
              assigned_to: item?.assigned_to,
            });
          })
        }
      });
      //console.log('marks', taskData)
      setMarks(taskData);
      setLoading(false);
    }
  },[wbsId])

  useEffect(() => {
    if (!marks) {
      getData();
    }
  }, [marks, getData]);

  const handleOpenTaskDetailModal = (selectedDate) => {
    const taskAvailable = marks.find(
      (x) =>
        new Date(x.date).toLocaleDateString() ===
        new Date(selectedDate).toLocaleDateString()
    );
    if (taskAvailable) {
      setOpenModal(true);
      dueDate = new Date(selectedDate).toLocaleDateString("fr-CA")
      setDueDate(dueDate);
    }
  };

  if (loading) {
    return (
      <>
        <LoadingSpinner />
      </>
    )
  }

  return (
    <>
      {errMsg ? (
        <Stack sx={{ width: '100%' }} spacing={2} className="alerts">
          <Alert severity="error">{errMsg}</Alert>
        </Stack>
      ) : (
        <></>
      )}
      <Calendar
        nextAriaLabel='nextBtn'
        prevAriaLabel='prevBtn'
        className="custom-calendar"
        prevLabel={<KeyboardArrowLeftIcon />}
        nextLabel={<KeyboardArrowRightIcon />}
        onClickDay={(value) => handleOpenTaskDetailModal(value)}
        tileContent={tileContent}
        tileClassName={({ date }) => {
          let currentValue =
            marks?.length &&
            marks?.find(
              (x) =>
                new Date(x.date).toLocaleDateString() ===
                new Date(date).toLocaleDateString()
            );
          if (currentValue) {
            return `calendar-priority-${currentValue.priority}`;
          }
        }}
      />


      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
          boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px ",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus={true}
      >
        <Typography
          sx={{
            paddingTop: "10px",
            paddingBottom: "10px",
            paddingRight: "24px",
            paddingLeft: "30px"
          }}
        >
          {popoverData?.length ? (
            <ul>
              {popoverData.map((item) => (
                <li key={item?.id}>{item.name}</li>
              ))}
            </ul>
          ) : (
            ""
          )}
        </Typography>
      </Popover>
      <Modal
        open={openModal}
        onClose={handleClose}
        modalTitle={`Due Assigned Tasks`}
        stylecustom={{ paddingRight: 0, width: "100vw" }}
      >
        <Box sx={style}>
          <Button onClick={handleClose}>Close</Button>
          <h2>WBS Task List</h2>
          <WBSTaskList taskData={taskData} />
        </Box>
      </Modal>
    </>
  );
};

export default TaskCalendar