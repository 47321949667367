import React, { useEffect, useState } from "react";
import { DataGrid } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Link from '@mui/material/Link';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import AddProjectData from "../CreateEditProjectData";
import CreateEdit from "../CreateEdit";
import CreateEditProjectRisk from "../CreateEditProjectRisk";
//import CreateEditProjectAuditTest from "./CreateEditProjectAuditTest";
import CreateEditProjectControl from "../CreateEditProjectControl";
import MultipleControlSelect from "../MultipleControlSelect";
import CreateEditProjectTask from "../CreateEditProjectTask";
import CreateEditProjectControlException from "../CreateEditProjectControlException";
//import CreateEditDocument from "./CreateEditDocument";
import "../../css/List.css";
import SpinnerComponent from '../LoadingSpinner';
import axios from 'axios';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/AddCircleOutline';
//import AddCircleIcon from '@mui/icons-material/AddCircle';
//import Checkbox from '@mui/material/Checkbox';
//import FrameworkVersion from "../views/FrameworkVersion";

//This class displays all lists in the app e.g. project groups, control classes etc.

/* 
********START all endpoints called
*/

export async function deleteData(id, dataType) {
  var body;
  var url = `${process.env.REACT_APP_API_URL}/${dataType}/${id}`;

  const response = axios(url, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${localStorage.access_token}`,
    },
    data: body,
  }).then(result => {
    console.log('delete result', result)
    return result;
  }).catch(error => { return error.message; })
  return response;
}

export async function loadData(dataType, projectId) {
  //setLoading(true);
  //call data endpoint for data type to set rows and columns
  var url = `${process.env.REACT_APP_API_URL}/${dataType}`;
  var method = "GET";
  if (projectId) {
    url = `${process.env.REACT_APP_API_URL}/projects/${projectId}`
  }
  if (dataType === "risk_categories") {
    url = `${process.env.REACT_APP_API_URL}/risk_categories/`;
  }
  const response = await axios(url, {
    method: method,
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${localStorage.access_token}`,
    },
    maxRedirects: 0,
  }).then(result => {
    return result;
  }).catch(error => { return error.message; })
  //setLoading(false);
  return response;

}

/* 
********END all endpoints called
*/

const columns = [
  { field: 'id', headerName: 'id', width: 120 },
];

const rows = [
  { id: "n/a" },
];

export default function List({
  dataType,
  showBreadcrumb,
  projectId,
}) {

  //axios error message
  const [errMsg, setErrMsg] = useState("");

  const [rowsData, setRowsData] = useState(null);
  const [colsData, setColsData] = useState(null);
  const [loading, setLoading] = useState(false);

  const [selected, setSelected] = useState([]);

  const [openDialog, setOpenDialog] = useState(null);
  const [openAddDialog, setOpenAddDialog] = useState(null);
  const [openCreateDialog, setOpenCreateDialog] = useState(null);
  const [openCreateProjectRiskDialog, setOpenCreateProjectRiskDialog] = useState(null);
  const [openCreateProjectControlDialog, setOpenCreateProjectControlDialog] = useState(null);
  const [openCreateProjectControlSingleDialog, setOpenCreateProjectControlSingleDialog] = useState(null);
  const [openCreateProjectTaskDialog, setOpenCreateProjectTaskDialog] = useState(null);
  const [openCreateProjectControlExceptionDialog, setOpenCreateProjectControlExceptionDialog] = useState(null);
  const [editObj, setEditObj] = useState(null);

  const [breadcrumb, setBreadcrumb] = useState(dataType);

  const [projectDataType, setProjectDataType] = useState();

  const handleClose = () => {
    setOpenDialog(false);
    setOpenAddDialog(false);
    setOpenCreateDialog(false);
    setOpenCreateProjectRiskDialog(false);
    setOpenCreateProjectControlDialog(false);
    setOpenCreateProjectTaskDialog(false);
    setOpenCreateProjectControlExceptionDialog(false);
    setOpenCreateProjectControlSingleDialog(false);
    setEditObj(null);
  }

  const [isSystemAdmin, setIsSystemAdmin] = useState(false);

  async function formatRowsCols(jsonData) {
    const rowsColsDefault = { rows: rows, cols: columns }
    if (dataType === 'control_classes' || dataType === 'control_families' || dataType === 'control_phases') {
      if (!jsonData?.data) {
        return rowsColsDefault
      } else {
        var filteredRowData = [];
        jsonData.data.items.forEach((key) => {
          filteredRowData.push({
            id: key["id"],
            name: key["name"],
            description: key["description"],
            //action: key.id,
          });
        })
        var colsVals = [
          {
            field: 'name',
            headerName: 'Name',
            width: 300,
            cellClass: "normalLineHeight"
          },
          {
            field: 'description',
            headerName: 'Description',
            width: 500,
            cellClass: "normalLineHeight"
          },
        ]
        const rowsCols = { rows: filteredRowData, cols: colsVals }
        return rowsCols
      }
    }
    //project_groups data type
    else if (dataType === "project_groups") {
      if (!jsonData?.data) {
        return rowsColsDefault
      }
      if (jsonData?.data != null) {
        var filteredRowDataProGrps = [];
        jsonData.data.forEach((key) => {
          filteredRowDataProGrps.push({
            id: key["id"],
            name: key["name"],
            description: key["description"],
            //action: key.id,
          });
        })
        var colsValsProGrps = [
          {
            field: 'name',
            headerName: 'Name',
            width: 300,
            renderCell: (params) => (
              <Link href={`/${dataType}/${params.row.id}`}>{params.value}</Link>
            )
          },
          {
            field: 'description',
            headerName: 'Description',
            width: 500,
            cellClass: "normalLineHeight"
          },
        ];
        const rowsCols = { rows: filteredRowDataProGrps, cols: colsValsProGrps }
        return rowsCols
      }
    }
    //risk_categories data type
    else if (dataType === "risk_categories") {
      if (!jsonData?.data) {
        return rowsColsDefault
      } else {
        var filteredRowDataRisk = [];
        jsonData.data.forEach((key) => {
          filteredRowDataRisk.push({
            id: key["id"],
            name: key["name"],
            description: key["description"],
            created_date: key["created_date"],
            last_updated_date: key["last_updated_date"],
          });
        })
        var colsValsRisk = [
          {
            field: 'name',
            headerName: 'Name',
            width: 300,
            renderCell: (params) => (
              <Link href={`/${dataType}/${params.row.id}`}>{params.value}</Link>
            )
          },
          {
            field: 'description',
            headerName: 'Description',
            width: 500,
            cellClass: "normalLineHeight"
          },
          {
            field: 'created_date',
            headerName: 'Created date',
            width: 200,
            cellClass: "normalLineHeight"
          },
          {
            field: 'last_updated_date',
            headerName: 'Last updated date',
            width: 200,
            cellClass: "normalLineHeight"
          },
          {
            field: 'action',
            headerName: 'Action',
            width: 200,
            renderCell: (params) => (
              <>
                <Button startIcon={<EditIcon />} onClick={() => { setEditObj(params.row); setOpenCreateDialog(true) }} />
                <Button startIcon={<DeleteIcon />} onClick={() => { setEditObj(params.row); setOpenDialog("delete") }} />
              </>
            )

          },
        ];
        const rowsCols = { rows: filteredRowDataRisk, cols: colsValsRisk }
        return rowsCols
      }
    }
    else {
      var filteredRowDataD = [];
      jsonData.data.items.forEach((key) => {
        let keystr = ""
        key.keywords.forEach((str) => {
          keystr = keystr + str.keyword.name + " "
        })
        filteredRowDataD.push({
          id: key.id,
          name: key.name,
          description: key.description,
          keywords: keystr,
          action: key.id,
        });
      })
      var colsValsD = [
        {
          field: 'name',
          headerName: 'Name',
          width: 200,
          renderCell: (params) => (
            <Link href={`/${dataType}/${params.row.id}`}>{params.value}</Link>
          )
        },
        {
          field: 'description',
          headerName: 'Description',
          width: 200,
          cellClass: "normalLineHeight"
        },
        {
          field: 'keywords',
          headerName: 'Keywords',
          width: 200,
          cellClass: "normalLineHeight"
        },
        {
          field: 'action',
          headerName: 'Action',
          width: 200,
          renderCell: (params) => (
            <Button startIcon={<EditIcon />} onClick={() => { setEditObj(params.row); setOpenCreateDialog(true) }} />
          )

        },
      ];
      const rowsCols = { rows: filteredRowDataD, cols: colsValsD }
      return rowsCols
    }
  }

  useEffect(() => {
    if (localStorage.getItem("system_role") === "4" ||
      localStorage.getItem("system_role") === "1"
    ) {
      setIsSystemAdmin(true)
    }

  }, [setIsSystemAdmin])

  useEffect(() => {
  }, [errMsg])

  useEffect(() => {
    if (rowsData === null) {
      getData();
      formatBreadCrumb();
    }
  });

  useEffect(() => {
    //console.log(rowsData)
  }, [rowsData])

  useEffect(() => {
    //console.log(colsData)
  }, [colsData])

  /*
   * This method gets all the data from the endpoint and formats it into the rows and columns for display.
  */
  async function getData() {
    setLoading(true);
    const jsonData = await loadData(dataType);
    const rowsColsFormatted = await formatRowsCols(jsonData)
    if (rowsColsFormatted?.rows && rowsColsFormatted?.cols) {
      setRowsData(rowsColsFormatted.rows)
      setColsData(rowsColsFormatted.cols)
    }
    setLoading(false);
  }

  const onRowsSelectionHandler = (ids) => {
    setSelected([...ids]);
  };

  const isOpen = () => {
    setEditObj(null);
    getData()
    handleClose();
  }

  const addControlData = async (data) => {
    if (data) {
      await getData();
    } else {
      var arrcopy = rowsData.filter(row => row.id !== data.id)
      var tmparray = [...arrcopy, data];
      setRowsData(tmparray);
      getData();
    }
    handleClose();

    //editObj = null
  }

  const refreshRows = async (rows) => {
    setRowsData(rows)
  }

  async function deleteSelectedRow() {
    if (rowsData?.length > 0) {
      var filteredArray = [...rowsData]
      for (let x = 0; x < selected.length; x++) {
        const response = await deleteData(selected[x], projectId, dataType);
        if (response?.status !== 200) {
          setErrMsg(`${response}`);
        }
        //setErrMsg(``)
        filteredArray = [...filteredArray.filter((row) => row.id !== selected[x])]
      }
      setRowsData(filteredArray);
    }

  };

  function formatBreadCrumb() {
    var str = String(dataType).split("_")
    str = str.join(" ").trim()
    setBreadcrumb(str);
  }

  return (
    <div style={{ height: '75vh', width: '75vw', marginTop: '0px', marginBottom: '65px' }} className={dataType + `list`}>
      {(showBreadcrumb &&
        <>
          <h1 className="dashboard-title">{dataType.replace("_", " ")} Summary Dashboard</h1>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/system-administration">
              System Administration
            </Link>
            <Typography color="text.primary">{breadcrumb}</Typography>
          </Breadcrumbs>
        </>)}

      {errMsg ? (
        <Stack sx={{ width: '100%' }} spacing={2} className="alerts">
          <Alert severity="error">{errMsg}</Alert>
        </Stack>
      ) : (
        <></>
      )}
      {(isSystemAdmin &&
        <Button
          variant="contained"
          className="secondary btn"
          startIcon={<DeleteIcon />}
          disabled={!selected.length}
          onClick={() =>
            selected.length ? setOpenDialog("delete") : null
          }
        >
          Delete
        </Button>
      )}
      {(projectId
        &&
        isSystemAdmin &&
        <Button
          startIcon={<AddIcon />}
          variant="outlined"
          onClick={() => { setProjectDataType(dataType); setOpenAddDialog(true); }}
        >
          Create
        </Button>)}

      {(
        isSystemAdmin &&
        <Button
          startIcon={<AddIcon />}
          variant="outlined"
          onClick={() => { setOpenCreateDialog(true); }}
        >
          Create
        </Button>)}

      <DataGrid
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'
        }
        rows={rowsData ? rowsData : rows}
        columns={colsData ? colsData : columns}
        pageSize={10}
        rowsPerPageOptions={[10, 25, 50, 100]}
        checkboxSelection
        loading={loading}
        components={{
          LoadingOverlay: SpinnerComponent,
        }}
        onSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
      />

      {(dataType !== "users" && <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="delete-confirm"
        aria-describedby="alert-dialog-delete-confirm"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this?
          </DialogContentText>
        </DialogContent>
        <DialogActions className="create-edit-dialog">
          <Button onClick={handleClose}>Close</Button>
          <Button onClick={() => { handleClose(); deleteSelectedRow(); }} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>)}

      <Dialog
        //fullScreen
        open={openAddDialog}
        onClose={handleClose}
        aria-labelledby="add-project-data"
        aria-describedby="alert-dialog-add-project-data"
      >
        <DialogActions className="create-edit-dialog">
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
        <DialogTitle id="alert-dialog-title">
          {editObj ? `Edit ${projectDataType}` : `Add ${projectDataType} to Project`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">

            <AddProjectData isOpen={isOpen} controlData={addControlData} editDataObj={editObj} dataType={projectDataType} projectId={projectId} />
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <Dialog
        //fullScreen
        open={openCreateDialog}
        onClose={handleClose}
        aria-labelledby="create-data"
        aria-describedby="alert-dialog-create-data"
      >
        <DialogActions className="create-edit-dialog">
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
        <DialogTitle id="alert-dialog-title">
          {editObj ? `Edit ${dataType}` : `Create ${dataType}`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <CreateEdit dataType={dataType} editDataObj={editObj} />
          </DialogContentText>
        </DialogContent>
      </Dialog>


      <Dialog
        //fullScreen
        open={openCreateProjectRiskDialog}
        onClose={handleClose}
        aria-labelledby="create-data"
        aria-describedby="alert-dialog-create-data"
      >
        <DialogActions className="create-edit-dialog">
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
        <DialogTitle id="alert-dialog-title">
          {`Create Risk for Project`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <CreateEditProjectRisk projectId={projectId} editDataObj={editObj} controlData={addControlData} isOpen={isOpen} />
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Dialog
        //fullScreen
        open={openCreateProjectControlDialog}
        onClose={handleClose}
        aria-labelledby="create-data"
        aria-describedby="alert-dialog-create-data"
      >
        <DialogActions className="create-edit-dialog">
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
        <DialogTitle id="alert-dialog-title">
          {editObj ? `Edit Project Control` : `Add Project Controls to Project`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <MultipleControlSelect isOpen={isOpen} projectId={projectId} rowsReturnData={refreshRows} />
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Dialog
        //fullScreen
        open={openCreateProjectControlSingleDialog}
        onClose={handleClose}
        aria-labelledby="create-data"
        aria-describedby="alert-dialog-create-data"
      >
        <DialogActions className="create-edit-dialog">
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
        <DialogTitle id="alert-dialog-title">
          {editObj ? `Edit Project Control` : `Add Project Control to Project`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <CreateEditProjectControl editDataObj={editObj} isOpen={isOpen} projectId={projectId} controlData={addControlData} />
          </DialogContentText>
        </DialogContent>
      </Dialog>


      <Dialog
        //fullScreen
        open={openCreateProjectTaskDialog}
        onClose={handleClose}
        aria-labelledby="create-data"
        aria-describedby="alert-dialog-create-data"
      >
        <DialogActions className="create-edit-dialog">
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
        <DialogTitle id="alert-dialog-title">
          {`Create Task for Project`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <CreateEditProjectTask isOpen={isOpen} controlData={addControlData} rowsData={rowsData} />
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <Dialog
        //fullScreen
        open={openCreateProjectControlExceptionDialog}
        onClose={handleClose}
        aria-labelledby="create-data"
        aria-describedby="alert-dialog-create-data"
      >
        <DialogActions className="create-edit-dialog">
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
        <DialogTitle id="alert-dialog-title">
          {`Create Exception for Project Control`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <CreateEditProjectControlException isOpen={isOpen} controlData={addControlData} projectId={projectId} rowsData={rowsData} editDataObj={editObj} />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>

  );
}