import React, { useEffect, useState, useCallback, useMemo } from "react";
import Button from '@mui/material/Button';
import { MenuItem, Select } from '@mui/material';
import { useForm, Controller } from "react-hook-form";
import axios from 'axios';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

//This class is for the create / edit control form on a project

export default function CreateEditTaskProjectControl({
  isOpen,
  projectId,
  controlData,
  taskData
}) {
  console.log(taskData)
  //axios error message
  const [errMsg, setErrMsg] = useState("");
  useEffect(() => {
  }, [errMsg])

  //used with select fields in form
  const [controlId, setControlId] = useState();

  //dropdown values 
  const [controls, setControls] = useState([]);

  //default option when no value selected in drop down
  const selectOption = useMemo(() => { return { value: "0", label: "Please select..." } }, [])

  const { control, handleSubmit } = useForm({
    defaultValues: {
      controlSelect: {},
    }
  });

  // handle change events on dropdowns
  const handleChangeControlSelect = (event) => {
    setControlId(event.target.value);
  }

  /* form submit */
  const onSubmit = async (data) => {
    const response = await postFormData(data);
    console.log(response)
    if (response.status === 200) {
      controlData(response.data)
      //controlData = response.data;
      isOpen(false);
      //update listonUI
      // var selectedControl = controls.filter(control => control.value === controlId)
      // var datastore = {};
      // datastore.id = selectedControl[0].value;
      // datastore.name = selectedControl[0].label;
      // controlData(datastore);
      //window.location.reload(false);
    } else {
      setErrMsg(`${response}`)
    }

    //isOpen(false);
  }

  async function postFormData(data) {
    var method = "PUT";
    //console.log(`task data id = ${controlId}`)
    var project_controls = []
    if (taskData.project_controls) {
      for (let p = 0; p < taskData.project_controls.length; p++) {
        project_controls.push(taskData.project_controls[p].TaskProjectControl.project_control_id)
      }
    }
    project_controls.push(controlId)
    var body = {
      "comments": "project control added to task",
      "project_controls": project_controls
    }
    var url = `${process.env.REACT_APP_API_URL}/tasks/${taskData?.id}`;
    const response = axios(url, {
      method: method,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.access_token}`,
      },
      data: body,
    }).then(result => {
      return result;
    }).catch(error => { return error.message; })
    return response;
  }
  const getControlData = useCallback(async () => {
    var url = `${process.env.REACT_APP_API_URL}/projects/project_controls/${projectId}`;

    const response = axios(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.access_token}`,
      },
    }).then(result => {
      return result;
    }).catch(error => { return error.message; })
    return response
  }, [projectId])

  const getDropDownOptions = useCallback(async () => {
    const jsonData = await getControlData();
    var tmpControlOptions = [];
    //console.log(jsonData.data.items)
    jsonData?.data?.items?.map((control) => {
      tmpControlOptions.push(
        {
          value: control.id,
          label: control.control.name
        }
      )
      return control
    })
    if (tmpControlOptions.length === 0) {
      setErrMsg("There are no addable controls available. Please add one to your project.");
    }
    /*for (let x = 0; x < tmpControlOptions.length; x++) {
      for (let y = 0; y < taskData.project_controls.length; y++) {
        //console.log(tmpControlOptions[x])
        if (parseInt(tmpControlOptions[x].value) === taskData.project_controls[y].TaskProjectControl.project_control_id) {
          tmpControlOptions.splice(x, 1)
        }
      }
    }*/
    var allOptions = [selectOption, ...tmpControlOptions];

    setControls(allOptions);
  }, [getControlData, selectOption])

  useEffect(() => {
    getDropDownOptions();
  }, [getDropDownOptions]);

  return (
    <div className="create edit task project control">
      {errMsg ? (
        <Stack sx={{ width: '100%' }} spacing={2} className="alerts">
          <Alert severity="error">{errMsg}</Alert>
        </Stack>
      ) : (
        <></>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-field control">
          <label>Control</label>
          <Controller
            name="controlSelect"
            control={control}
            defaultValue={0}
            render={({ field }) =>
              <Select
                {...field}
                onChange={handleChangeControlSelect}
                defaultValue={0}
                value={controlId ? controlId : 0}
              >
                {
                  controls?.map((control) => {
                    return <MenuItem
                      value={control.value}
                      key={control.value}>
                      {control.label}
                    </MenuItem>
                  })
                }
              </Select>
            }
          />
        </div>

        <div>
          <Button type="submit" variant="contained" >Submit</Button>
        </div>
      </form>
    </div>
  )
}