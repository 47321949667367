import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from '@mui/material/Button';
import { MenuItem, Select } from '@mui/material';
import { useForm, Controller } from "react-hook-form";
import axios from 'axios';
import '../css/InviteUser.css';

export default function Invite(isOpen) {
    const [systemRoleId, setSystemRoleId] = useState();
    const [email, setEmail] = useState();
    //default option when no value selected in drop down
    const selectOption = { value: 0, label: "Please select..." };
    const systemRoles = [
        selectOption,
        { value: "1", label: "Project Manager" },
        { value: "4", label: "System Administrator" },
        { value: "5", label: "Billing Administrator" },
        { value: "2", label: "Analyst" },
        { value: "3", label: "Auditor" },
        { value: "6", label: "Project Stakeholder" },
      ]

    const { control, handleSubmit } = useForm({
        defaultValues: {
            email: '',
            systemRoleSelect: {},
        }
    });

    // handle change events on dropdowns
    const handleChangeSystemRoleSelect = (event) => {
        setSystemRoleId(event.target.value);
    }

    /* form submit */
    const onSubmit = async () => {
        const checkEmail = await checkUniqueEmail();
        //console.log(`check email response = ${JSON.stringify(checkEmail)}`)
        if (checkEmail !== `Request failed with status code 409`) {
            await postFormData();
            //console.log(`response = ${JSON.stringify(response)}`)
            //isOpen(false);
            window.location.reload(false);
        }
        if (checkEmail === `Request failed with status code 409`) {
            alert(`A user with that email address already exists`)
        }
        
    }

    async function postFormData() {
        var url = `${process.env.REACT_APP_API_URL}/tenants/user_invite`;
        var body = {
            "email": email,
            "system_role": systemRoleId,
        };
        var method = "POST";
        const response = axios(url, {
            method: method,
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.access_token}`,
            },
            data: body,
        }).then(result => {
            return result;
        }).catch(error => { return error.message; })
        return response;
    } 

    const checkUniqueEmail = async () => {
        var method = "POST";
        var url = `${process.env.REACT_APP_API_URL}/tenants/check_unique_email?email=${email}`
        const response = axios(url, {
          method: method,
          headers: {
            "Content-Type": "application/json",
          },
        }).then(result => {
          return result;
        }).catch(error => { return error.message; })
        return response;
      }

    return (
            <form onSubmit={handleSubmit(onSubmit)} className="invite users">
                <div className="form-field control">
                    <label>System Role</label>
                    <Controller
                        name="systemRoleSelect"
                        control={control}
                        render={({ field }) =>
                            <Select
                                {...field}
                                onChange={handleChangeSystemRoleSelect}
                                value={systemRoleId ? systemRoleId : 0}
                                inputProps={{ "data-testid": "system-role" }}
                            >
                                {
                                    systemRoles?.map((role) => {
                                        return <MenuItem
                                            value={role.value}
                                            key={role.value}>
                                            {role.label}
                                        </MenuItem>
                                    })
                                }
                            </Select>
                        }
                    />
                </div>
                <div><TextField type="email" required id="email" value={email} label="Email" onChange={e => setEmail(e.target.value)}
                    style={{
                        width: 500
                    }} />
                </div>
                <div className="invite-user">
                    <Button className="sign-in" type="submit" variant="contained">Invite</Button>
                </div>
            </form>
    )
}