import React, { useState, useEffect, useCallback, useRef } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import '../css/SubscriptionPlans.css';
import axios from "axios";
import TextField from "@mui/material/TextField";
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

export async function checkLicensesAvailable(heads) {
    var url = `${process.env.REACT_APP_API_URL}/subscriptions/available_user_licenses`;
    var method = `POST`;
    const response = axios(url, {
        method: method,
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.access_token}`,
        },
        data: {
            "number_of_licenses": heads
        },
    }).then(result => {
        return result;
    }).catch(error => { return error.message; })
    return response;
}

export async function getActiveUsersResp() {
    var url = `${process.env.REACT_APP_API_URL}/users/get_active_users_tenant`;
    const response = await axios(url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.access_token}`,
        },
    }).then(result => {
        return result;
    }).catch(error => { return error.message; })
    return response;
};

export async function getCustomerId() {
    var url = `${process.env.REACT_APP_API_URL}/tenants/customer_id`;
    const response = await axios(url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.access_token}`,
        },
    }).then(result => {
        return result;
    }).catch(error => { return error.message; })
    return response;
}

export async function getBillingInfo(id) {
    var url = `${process.env.REACT_APP_API_URL}/subscriptions/list_payment_methods`;
    var body = JSON.stringify(
        {
            customer_id: id,
            type: "card",
        })
    await axios(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.access_token}`,
        },
        data: body,
    }).then(result => {
        return result;
    }).catch(error => { return error.message; })
};

export default function SubscriptionPlans({ isOpen, plan, registering }) {

    const freecard = useRef({
        plan: "Trial Plan",
        users: "1 User",
        cost: "Free",
        units: "for 30 days / 8 days remaining",
        checked: true,
        disabled: true,
    })

    const [cardsContent, setCardsContent] = useState();

    const [cards, setCards] = useState();

    const [selectedPlan, setSelectedPlan] = useState();

    const [heads, setHeads] = useState();

    const [invoice, setInvoice] = useState();

    const [customerId, setCustomerId] = useState();

    const [paymentMethodId, setPaymentMethodId] = useState();

    const [activeUsers, setActiveUsers] = useState();

    //axios error message
    const [errMsg, setErrMsg] = useState("");

    const getSubscriptionPlans = useCallback(async () => {
        var carditems = [];
        if (!plan) {
            carditems.push(freecard);
        }
        var url = `${process.env.REACT_APP_API_URL}/subscriptions/get_all_plans`;
        await axios(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.access_token}`,
            },
        }).then(result => {
            result?.data?.forEach(item => {
                Object.keys(item).forEach((key) => {
                    //checkbox for selected plan
                    let priceid = ""
                    var is_checked = false
                    if (plan) {
                        priceid = plan.plan.id
                        if (priceid === item[key]["price_id"]) {
                            is_checked = true
                        }
                    }

                    let curitem = {
                        plan: key,
                        users: 'Unlimited Users',
                        cost: `${item[key]["price"] / 100}`,
                        units: item[key]["interval"],
                        checked: is_checked,
                        priceid: item[key]["price_id"],
                        disabled: false,
                    }
                    carditems.push(curitem);
                })
            });
            setCardsContent(carditems);
            return result;
        }).catch(error => { return error.message; })
    }, [freecard, plan])

    const updateInvoice = useCallback(async () => {
        if (plan && !selectedPlan) {
            let totalcostVal = plan?.plan?.amount * heads / 100
            setInvoice(totalcostVal)
        }
        else if (plan && selectedPlan) {
            let totalcostVal = parseInt(selectedPlan?.cost) * heads
            setInvoice(totalcostVal)
        }
    }, [plan, selectedPlan, heads])

    const selectPlan = useCallback(async (index) => {

        setHeads(activeUsers);
        //plan(null);

        var newCardsContent = [];
        for (var i = 0; i < cardsContent.length; i++) {
            var curItem = cardsContent[i];
            if (i === index) {
                curItem.checked = true;
            } else {
                curItem.checked = false;
            }
            newCardsContent.push(curItem);
        }
        setCardsContent(newCardsContent);
        //setEachCardContents();
        setSelectedPlan(cardsContent[index]);
        setInvoice(cardsContent[index].cost * activeUsers);
        updateInvoice();
    }, [activeUsers, cardsContent, updateInvoice])

    const setEachCardContents = useCallback(async () => {
        setCards(cardsContent.map(
            (card, i) => {
                return (
                    <div className={`cards id-${i}`}>
                        <Card onClickCapture={() => { selectPlan(i); updateInvoice(); }} className={card.checked ? `blue-select` : ``} >
                            <CardActions>
                                <FormGroup>
                                    <FormControlLabel control={<Radio checked={card.checked} disabled={card.disabled} />} label={card.plan} />
                                </FormGroup>
                            </CardActions>
                            <CardContent>
                                <div className='costing'>
                                    <Typography sx={{ fontSize: 18 }}>
                                        {card.users}
                                    </Typography>
                                    <Typography sx={{ fontSize: 18 }} color="text.secondary" className='card-right'>
                                        {(card.cost !== `Free` && `$${card.cost}.00 per user`)}
                                        {(card.cost === `Free` && card.cost)}
                                    </Typography>
                                    <Typography sx={{ fontSize: 14 }} color="text.secondary" className='card-right'>
                                        per {card.units}
                                    </Typography>

                                </div>

                            </CardContent>
                        </Card>
                    </div>
                )
            }
        ))

    }, [cardsContent, selectPlan, updateInvoice])

    const getCustomerBillingPayment = useCallback(async () => {
        const response = await getCustomerId();
        if (response?.data) {
            setCustomerId(response.data)
            customerId(response.data)
            const billingResponse = await getBillingInfo(response.data)
            if (billingResponse?.data) {
                setPaymentMethodId(billingResponse.data.data[0].id)
            }
        } else {
            setErrMsg(`No customer ID found`)
        }
    }, [customerId])


    const getActiveUsers = async () => {
        const response = await getActiveUsersResp();
        if (response?.data) {
            setActiveUsers(response.data)
        }

    }

    useEffect(() => {
        if (!registering) {
            getCustomerBillingPayment();
        }
    }, [getCustomerBillingPayment, registering]);

    useEffect(() => {
        if (!activeUsers) {
            getActiveUsers();

        }
    }, [activeUsers])

    useEffect(() => {
        if (activeUsers) {
            setHeads(activeUsers);
        }

    }, [activeUsers])

    useEffect(() => {
        getSubscriptionPlans();
    }, [getSubscriptionPlans])

    useEffect(() => {
        //console.log('cards content', cardsContent)
    }, [cardsContent])

    useEffect(() => {

    }, [cards])

    useEffect(() => {
        updateInvoice();
    }, [heads, updateInvoice])

    useEffect(() => {

    }, [invoice])

    useEffect(() => {
    }, [errMsg])

    const createSubscription = async () => {

        const resp = await checkLicensesAvailable(heads);

        if (resp === 'Request failed with status code 400') {
            setErrMsg(`Sorry not enough licenses for your ${activeUsers} active users. Please upgrade your plan or deactivate your users.`)
            return resp;
        }
        if (resp.status === 200) {
            var url = `${process.env.REACT_APP_API_URL}/subscriptions/create`;
            var body = JSON.stringify(
                {
                    plan: `${selectedPlan ? selectedPlan?.units : plan?.plan?.interval}`,
                    payment_method_id: paymentMethodId,
                    price_key: `${selectedPlan ? selectedPlan?.priceid : plan?.plan?.id}`,
                    member_count: heads,
                    status: "active",
                    tenant_id: localStorage.tenant_id,
                    is_active: 1,
                })

            const response = await axios(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.access_token}`,
                },
                data: body,
            }).then(result => {
                window.location.reload(false);
                return result;
            }).catch(error => { return error.message; })
            return response;
        }

    }

    const setPlan = async () => {
        const respCreateNew = await createSubscription();
        if (respCreateNew.status === 200) {
            window.location.reload(false);
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        await setPlan();
    }


    useEffect(() => {
        if (cardsContent) {
            setEachCardContents();
        }
    }, [cardsContent, setEachCardContents])

    return (
        <>
            <form className='subscription-plans' onSubmit={handleSubmit}>
                <div id='cards'>
                    {cards ? cards : <></>}
                </div>
                {errMsg ? (
                    <Stack sx={{ width: '100%' }} spacing={2} className="alerts">
                        <Alert severity="error">{errMsg}</Alert>
                    </Stack>
                ) : (
                    <></>
                )}
                <h3>Add users</h3>
                <div className='headsNum'>
                    <FormGroup>
                        <TextField
                            type="number"
                            id="outlined-basic"
                            variant="outlined"
                            onChange={(e) => { setHeads(e.target.value); updateInvoice(); }}
                            value={heads}
                            defaultValue={activeUsers}
                        />
                    </FormGroup>
                </div>
                <h3>Estimated Invoice</h3>
                <h3>${invoice}</h3>
                {(!registering && <Button className="sign-in" type="submit" variant="contained">Save</Button>)}
                {(registering
                    &&
                    <Button
                        className="sign-in"
                        variant="contained"
                        onClick={() => {
                            window.location.replace("/payment");
                            localStorage.setItem("price_key", selectedPlan.priceid);
                            localStorage.setItem("plan", selectedPlan.units);
                            localStorage.setItem("heads", heads);
                        }}>Continue</Button>
                )}
            </form>
        </>
    );
}