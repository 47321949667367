import React, { useEffect, useState, useCallback, useMemo } from "react";
import Button from '@mui/material/Button';
import { MenuItem, Select } from '@mui/material';
import { useForm, Controller } from "react-hook-form";
import axios from 'axios';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

//This class is for the create / edit control form on a project

export default function CreateEditTaskProjectRisk({
  isOpen,
  projectId,
  controlData,
  taskData
}) {
  //axios error message
  const [errMsg, setErrMsg] = useState("");
  useEffect(() => {
  }, [errMsg])

  //used with select fields in form
  const [riskId, setRiskId] = useState();

  //dropdown values 
  const [risks, setRisks] = useState([{ value: "0", label: "Please select..." }]);

  //default option when no value selected in drop down
  const selectOption = useMemo(() => { return { value: "0", label: "Please select..." } }, [])

  const { control, handleSubmit } = useForm({
    defaultValues: {
      riskSelect: {},
    }
  });

  // handle change events on dropdowns
  const handleChangeRiskSelect = (event) => {
    setRiskId(event.target.value);
  }

  /* form submit */
  const onSubmit = async (data) => {
    const response = await postFormData(data);
    if (response.status === 200) {
      controlData(response.data)
      isOpen(false);
      //window.location.reload(false);
    } else {
      setErrMsg(`${response}`)
    }

    //isOpen(false);
  }

  async function postFormData(data) {
    var method = "PUT";
    var project_risks = []
    //console.log(taskData.risks)
    if (taskData.risks) {
      for (let p = 0; p < taskData.risks.length; p++) {
        project_risks.push(taskData.risks[p].Risk.id)
      }
    }
    project_risks.push(riskId)
    var body = {
      "comments": "risk added to task",
      "risks": project_risks
    }
    var url = `${process.env.REACT_APP_API_URL}/tasks/${taskData?.id}`;
    const response = axios(url, {
      method: method,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.access_token}`,
      },
      data: body,
    }).then(result => {
      return result;
    }).catch(error => { return error.message; })
    return response;
  }
  const getProjectData = useCallback(async () => {
    var url = `${process.env.REACT_APP_API_URL}/projects/${projectId}`;

    const response = axios(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.access_token}`,
      },
    }).then(result => {
      return result;
    }).catch(error => { return error.message; })
    return response
  }, [projectId])

  const getDropDownOptions = useCallback(async () => {
    const jsonData = await getProjectData();
    var tmpRiskOptions = [];
    jsonData.data.risks.forEach((risk) => {
      tmpRiskOptions.push(
        {
          value: risk.id,
          label: risk.name
        }
      )
    })
    if (tmpRiskOptions.length < 1) {
      setErrMsg("There are no addable risks available. Please add one to your project.");
    }
    //for (let x = 0; x < tmpRiskOptions.length; x++) {
      //for (let y = 0; y < taskData.risks.length; y++) {
        //console.log(taskData.risks[y])
        //if (parseInt(tmpRiskOptions[x].value) === taskData.risks[y].TaskRisk.risk_id) {
          //tmpRiskOptions.splice(x, 1)
        //}
      //}
    //}
    var allOptions = [selectOption, ...tmpRiskOptions];

    setRisks(allOptions);
  }, [getProjectData, selectOption])

  useEffect(() => {
    getDropDownOptions();
  }, [getDropDownOptions]);

  return (
    <div className="create edit task project risk">
      {errMsg ? (
        <Stack sx={{ width: '100%' }} spacing={2} className="alerts">
          <Alert severity="error">{errMsg}</Alert>
        </Stack>
      ) : (
        <></>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-field risk">
          <label>Risk</label>
          <Controller
            name="riskSelect"
            control={control}
            defaultValue={0}
            render={({ field }) =>
              <Select
                {...field}
                onChange={handleChangeRiskSelect}
                defaultValue={0}
                value={riskId ? riskId : 0}
              >
                {
                  risks?.map((risk) => {
                    return <MenuItem
                      value={risk.value}
                      key={risk.value}>
                      {risk.label}
                    </MenuItem>
                  })
                }
              </Select>
            }
          />
        </div>

        <div>
          <Button type="submit" variant="contained" >Submit</Button>
        </div>
      </form>
    </div>
  )
}