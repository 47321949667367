import React, { useState, useEffect, useCallback } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import CreateEditProjectControlAssessment from '../components/CreateEditProjectControlAssessment';
import SpinnerComponent from '../components/LoadingSpinner';
import axios from 'axios';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

/* This is a generic view for returning details on a data object */

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default function ProjectControlAssessment({ projectId, id }) {
  const [data, setData] = useState([]);
  const [controlData, setControlData] = useState([]);
  const [editOpen, setEditOpen] = useState(false);
  const [isSystemAdmin, setIsSystemAdmin] = useState(false);

  useEffect(() => {
    if (
      localStorage.getItem("system_role") === "4" ||
      localStorage.getItem("system_role") === "1" ||
      localStorage.getItem("system_role") === "2"
    ) {
      setIsSystemAdmin(true)
    }
  }, [setIsSystemAdmin])

  //axios error message
  const [errMsg, setErrMsg] = useState("");
  useEffect(() => {
  }, [errMsg])

  const handleClose = () => {
    setEditOpen(false);
  }

  const EditData = () => {
    setEditOpen(true);
  }

  /*useEffect(() => {
  }, [data])

  useEffect(() => {
    //if (projectId) {
      getData();
    }
  }, [projectId, getData])*/
  const loadData = useCallback(async () => {
    //call data endpoint for data type to set rows and columns
    var url = `${process.env.REACT_APP_API_URL}/projects/${projectId}`;

    const response = axios(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.access_token}`,
      },
    }).then(result => {
      return result;
    }).catch(error => { return error.message; })
    return response;
  }, [projectId])

  const getData = useCallback(async () => {
    const jsonData = await loadData();
    if (jsonData !== "Request failed with status code 404" && jsonData !== "Network Error") {
      setData(jsonData.data);
      var curControl = []
      var controlObj = jsonData.data?.project_controls?.filter(pc => pc.id === id)
      curControl.push(controlObj);
      setControlData(curControl[0][0])

    } else {
      setErrMsg(`${jsonData}`)
    }
  }, [id, loadData])

  const isOpen = () => {
    handleClose();
  }

  const addControlData = (data) => {
    getData();
  }

  useEffect(() => {
    if (data.length === 0) {
      getData();
    }

  }, [data, getData])

  return (
    <>
      {errMsg ? (
        <Stack sx={{ width: '100%' }} spacing={2} className="alerts">
          <Alert severity="error">{errMsg}</Alert>
        </Stack>
      ) : (
        <></>
      )}
      <div className="details-view projectControlAssess">
        {(!data && <SpinnerComponent />)}
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="/system-administration">
            System Administration
          </Link>
          <Link underline="hover" color="inherit" href={`/projects/list`}>
            Projects
          </Link>
          <Link underline="hover" color="inherit" href={`/projects/${projectId}`}>
            {data?.name}
          </Link>
          <Link underline="hover" color="inherit" href={`/projects/${projectId}/controls`}>
            Controls
          </Link>
          <Typography color="text.primary">{controlData?.control?.name}</Typography>
        </Breadcrumbs>
        <h1>{controlData?.assessment?.name}</h1>
        {isSystemAdmin ? <Button variant="contained" onClick={EditData}>Edit</Button> : <></>}
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableBody>
              <StyledTableRow >
                <StyledTableCell component="th" scope="row">
                  <strong>Assessment</strong>
                </StyledTableCell>
                <StyledTableCell align="left">
                  {controlData?.assessment?.name}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow >
                <StyledTableCell component="th" scope="row">
                  <strong>Assessment Status</strong>
                </StyledTableCell>
                <StyledTableCell align="left">
                  {controlData?.assessment?.status}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow >
                <StyledTableCell component="th" scope="row">
                  <strong>CAP / PO&AM (Plan of Action & Milestone) Assessment Confirmed</strong>
                </StyledTableCell>
                <StyledTableCell align="left">
                  {controlData?.assessment?.is_assessment_confirmed}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow >
                <StyledTableCell component="th" scope="row">
                  <strong>Framework</strong>
                </StyledTableCell>
                <StyledTableCell align="left">
                  <ul>
                    {controlData?.control?.framework_versions?.map((fv) => {
                      return <Link href={`/frameworks/${fv.framework?.id}/framework_versions/${fv.id}`}>{fv.version_prefix} {fv.framework?.name} {fv.version_suffix}</Link>
                    })}
                  
                  </ul>
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow >
                <StyledTableCell component="th" scope="row">
                  <strong>Control Family</strong>
                </StyledTableCell>
                <StyledTableCell align="left">
                  {controlData?.control_family?.name}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow >
                <StyledTableCell component="th" scope="row">
                  <strong>Control Phase</strong>
                </StyledTableCell>
                <StyledTableCell align="left">
                  {controlData?.control_phase?.name}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow >
                <StyledTableCell component="th" scope="row">
                  <strong>Control Status</strong>
                </StyledTableCell>
                <StyledTableCell align="left">
                  {controlData?.control_status?.name}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow >
                <StyledTableCell component="th" scope="row">
                  <strong>Control Class</strong>
                </StyledTableCell>
                <StyledTableCell align="left">
                  {controlData?.control_class?.name}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow >
                <StyledTableCell component="th" scope="row">
                  <strong>Mitigation Percentage</strong>
                </StyledTableCell>
                <StyledTableCell align="left">
                  {controlData?.mitigation_percentage}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow >
                <StyledTableCell component="th" scope="row">
                  <strong>Keywords</strong>
                </StyledTableCell>
                <StyledTableCell align="left">
                  <ul>
                    {(data?.keywords && (
                      data?.keywords.map((keyword) => {
                        return <li>
                          <Link href={`/keywords/${keyword.keyword.id}`}>{keyword.keyword.name}</Link>
                        </li>
                      })
                    )
                    )}
                  </ul>
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Dialog
          //fullScreen
          open={editOpen}
          TransitionComponent={Transition}
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          className={`dialog edit`}
        >
          <DialogActions className="create-edit-dialog">
            <Button onClick={handleClose}>Cancel</Button>
          </DialogActions>
          <DialogTitle>Edit {controlData?.control?.name}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <CreateEditProjectControlAssessment dataType='assessments' editObj={controlData} projectId={projectId} controlData={addControlData} isOpen={isOpen} />
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>
    </>
  )
}