import React, { useEffect, useState, useCallback, useMemo } from "react";
import TextField from "@mui/material/TextField";
import Button from '@mui/material/Button';
import { MenuItem, Select } from '@mui/material';
import { useForm, Controller } from "react-hook-form";
import FormHelperText from '@mui/material/FormHelperText';
import axios from 'axios';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

/* This code is used for creating and editing data objects: frameworks, controls, 
 * projects, project groups, control classes etc.
*/

export async function postData(data, editDataObj, type, keywordSelections, controlFrameworkId, projectGroupId, projectAdminId, projectStatus, frameworkIsGlobal) {
  //look at keywords
  if (!/^[a-z,]*$/.test(data?.newKeywords)) {
    return `Sorry your keywords contain values other than lowercase characters and commas`
  } else {
    //clean up duplicate vals
    var cleanedKeywords = []
    var enteredKeywords = data.newKeywords.split(',')
    for (let x = 0; x < enteredKeywords.length; x++) {
      let match = false
      for (let y = 0; y < keywordSelections.length; y++) {
        if (enteredKeywords[x] === keywordSelections[y].name) {
          match = true
        }
      }
      if (match === false) {
        cleanedKeywords.push(enteredKeywords[x])
      }
    }
    for (let p = 0; p < keywordSelections.length; p++) {
      cleanedKeywords.push(keywordSelections[p].name)
    }
    var cleanedWords = cleanedKeywords.join(',')
    var url = `${process.env.REACT_APP_API_URL}/${type}/?keywords=${cleanedWords}`;
    if (type) {
      url = `${process.env.REACT_APP_API_URL}/${type}/?keywords=${cleanedWords}`;
    }
    var body = JSON.stringify(
      {
        name: data.name,
        description: data.description || "",
        guidance: data.guidance || "",
        framework_id: controlFrameworkId,
        project_group_id: parseInt(projectGroupId),
        project_admin_id: parseInt(projectAdminId),
        order: data.order,
        status: projectStatus,
        comments: data.comments,
        is_global: frameworkIsGlobal,
      })
    var method = "POST";
    //if a data object is being edited
    if (editDataObj) {
      method = "PUT";
      url = `${process.env.REACT_APP_API_URL}/${type}/${editDataObj.id}?keywords=${cleanedWords}`;
      if (type === "keyword") {
        url = `${process.env.REACT_APP_API_URL}/${type}/${editDataObj.id}`;
        body = {
          "id": editDataObj?.id,
          "name": data.name,
        }
      }
    }
    try {
      const response = await axios(url, {
        method: method,
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.access_token}`,
        },
        data: body,
      })
      return response;
    } catch (err) {
      if (!err?.response) {
        return "No Server Response";
      } else if (err.response?.status) {
        const status = String(err.response?.status)
        if (status === "422") {
          return `Validation error.`
        } else {
          return `There was a ${err.response?.status} error`
        }
      } else {
        return "There was an error"
      }
    }
  }
}

export async function getKeywords() {
  const url = `${process.env.REACT_APP_API_URL}/keyword/`
  const response = axios(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${localStorage.access_token}`,
    },
  }).then(result => {
    return result;
  }).catch(error => { return error.message; })
  return response;
}

async function getData (type, isEditDataObj) {
  //call data endpoint for data type to set rows and columns
  var url = `${process.env.REACT_APP_API_URL}/${type}`;
  if (type === `project_groups`) {
    url = `${process.env.REACT_APP_API_URL}/${type}/`;
  }
  if (type === `users`) {
    url = `${process.env.REACT_APP_API_URL}/${type}/get_users_tenant`;
  }
  if (isEditDataObj && type === "framework_versions") {
    url = `${process.env.REACT_APP_API_URL}/framework_versions`;
  }
  try {
    const response = await axios(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.access_token}`,
      },
    })
    return response;
  } catch (err) {
    if (!err?.response) {
      return "No Server Response";
    } else if (err.response?.status) {
      const status = String(err.response?.status)
      if (status === "422") {
        return `Validation error.`
      } else {
        return `There was a ${err.response?.status} error`
      }
    } else {
      return "There was an error"
    }
  }
}

export default function CreateEdit({
  dataType,
  editDataObj,
  handleComplete,
  controlFrameworkId,
  isWizard,
  setProject }) {

  const [errMsg, setErrMsg] = useState("");

  const [frameworkIsGlobal, setFrameworkIsGlobal] = useState(editDataObj?.framework?.is_global ? editDataObj?.framework?.is_global : false);
  const [projectGroups, setProjectGroups] = useState([{ value: "0", label: "Please select..." }]);
  const [projectAdmin, setProjectAdmin] = useState([{ value: "0", label: "Please select..." }]);

  //project admin select data
  const [projectAdminId, setProjectAdminId] = useState(editDataObj?.project_admin?.id);

  //project group select data
  const [projectGroupId, setProjectGroupId] = useState(editDataObj?.project_group?.id);

  //project status select data
  const [projectStatus, setProjectStatus] = useState(editDataObj?.status);

  const selectOption = useMemo(() => { return { value: "0", label: "Please select..." } }, [])

  const [isSuperUser, setIsSuperUser] = useState(null);

  const [keywords, setKeywords] = useState([{ id: "0", name: "Please select..." }]);

  const [keywordSelections, setKeywordSelections] = useState([]);

  const [keywordId, setKeywordId] = useState();
  const keywordSelectOption = useMemo(() => { return { id: "0", name: "Please select..." } }, [])

  useEffect(() => {
    if (localStorage.getItem("is_superuser") === "true") {
      setIsSuperUser(true)
    } else {
      setIsSuperUser(false)
    }

  }, [setIsSuperUser])

  const projectStatusValues = [
    selectOption,
    { value: "Active", label: "Active" },
    { value: "On Hold", label: "On Hold" },
    { value: "Completed", label: "Completed" },
    { value: "Cancelled", label: "Cancelled" },
  ]

  useEffect(() => {
    if (editDataObj?.keywords) {
      let options = []
      for (let i = 0; i < editDataObj?.keywords.length; i++) {
        options.push({ id: editDataObj?.keywords[i].id, name: editDataObj?.keywords[i].keyword.name })
      }
      setKeywordSelections(options)
    }
  }, [editDataObj])

  useEffect(() => {
  }, [errMsg])

  const removeKeyword = (key) => {
    var tmpfilter = keywordSelections.filter(keyword => keyword.id !== key.id)
    setKeywordSelections(tmpfilter);
    var keyword = keywordSelections.filter(word => word.id === key.id)
    var name = keyword[0].name
    setKeywords(keywords => [...keywords, { name: name, id: key.id }]);
  }

  const { control, handleSubmit } = useForm({
    defaultValues: {
      name: editDataObj?.name,
      description: editDataObj?.description,
      guidance: editDataObj?.guidance,
      keywords: editDataObj?.keywords,
      comments: editDataObj?.comments,
      order: editDataObj?.order,
      framework: {},
      projectGroup: {},
      projectAdmin: {},
      projectStatus: {},
      newKeywords: "",
      keywordSelect: {},
    }
  });

  const handleChangeAdminSelect = (event) => {
    setProjectAdminId(event.target.value);
  }

  const handleChangeProjectGroupSelect = (event) => {
    setProjectGroupId(event.target.value);
  }

  const handleChangeProjectStatusSelect = (event) => {
    setProjectStatus(event.target.value);
  }

  const onSubmit = async (data) => {
    const response = await postData(
      data, 
      editDataObj, 
      dataType, 
      keywordSelections, 
      controlFrameworkId, 
      projectGroupId, 
      projectAdminId, 
      projectStatus, 
      frameworkIsGlobal
    );
    //if (response.error) {
    //alert(`There was an error: ${JSON.stringify(response)}`)
    //}
    if (!isWizard) {
      window.location.reload(false);
    }
    if (isWizard && response?.data) {
      handleComplete(true);
      setProject(response?.data)
    }

  }

  const handleChangeKeywordSelect = (event) => {
    setKeywordId(event.target.value);
    var keyword = keywords.filter(word => word.id === event.target.value)
    var name = keyword[0].name
    setKeywordSelections(keywordSelections => [...keywordSelections, { name: name, id: event.target.value }]);
    var tmpfilterSelect = keywords.filter(keyword => keyword.id !== event.target.value)
    setKeywords(tmpfilterSelect)
  }

  const handleSwitchChange = () => {
    if (frameworkIsGlobal === true) {
      setFrameworkIsGlobal(false)
    }
    if (frameworkIsGlobal === false) {
      setFrameworkIsGlobal(true)
    }
  }
  const getOptions = useCallback(async () => {
    if (dataType !== "keyword") {
      const jsonDataKey = await getKeywords();
      if (jsonDataKey === "Request failed with status code 404") {
        setKeywords([keywordSelectOption])
        setErrMsg("Request unsuccessful")
      }
      if (jsonDataKey.data) {
        const tmpoptions = jsonDataKey.data.map((word) => ({
          id: word.id,
          name: word.name,
        }));
        var allOptions = [keywordSelectOption, ...tmpoptions];
        setKeywords(allOptions);
      }
    }
    
    if (dataType === "projects") {
      const jsonData = await getData("project_groups", editDataObj);
      if (jsonData?.error) {
        //handle the error
        alert(`There was an error ${JSON.stringify(jsonData.error)}`)
      }
      if (jsonData?.data) {
        var tmpprogroups = [];
        jsonData.data.forEach(group => {
          tmpprogroups.push(
            {
              value: group.id,
              label: group.name
            }
          )
        })
        var allOptionsG = [selectOption, ...tmpprogroups];
        setProjectGroups(allOptionsG);
      }
      const jsonDataUsers = await getData("users", editDataObj);
      if (jsonDataUsers?.error) {
        //handle the error
        alert(`There was an error ${JSON.stringify(jsonDataUsers.error)}`)
      }
      if (jsonDataUsers?.data) {
        var tmpusers = [];
        jsonDataUsers.data.forEach(user => {
          tmpusers.push(
            {
              value: user.id,
              label: user.email
            }
          )
        })
        var userOptions = [selectOption, ...tmpusers];
        setProjectAdmin(userOptions);
      }
    }
  }, [dataType, editDataObj, selectOption, keywordSelectOption])

  useEffect(() => {
    if (dataType === "controls" || dataType === "projects" || dataType === "frameworks" || dataType === "project_evaluations") {
      getOptions();
    }

  }, [dataType, getOptions]);

  return (
    <>
      {errMsg ? (
        <Stack sx={{ width: '100%' }} spacing={2} className="alerts">
          <Alert severity="error">{errMsg}</Alert>
        </Stack>
      ) : (
        <></>
      )}
      <form onSubmit={handleSubmit(onSubmit)} className={dataType + 'createEdit'}>
        <div className="form-field name">
          <Controller
            name="name"
            control={control}
            render={({ field }) => <TextField required label="Name" {...field} fullWidth />}
          />
        </div>
        {(dataType !== "keyword" &&
          <div className="form-field description">
            <Controller
              name="description"
              control={control}
              render={({ field }) =>
                <TextField
                  {...field}
                  multiline
                  rows={4}
                  label="Description"
                  fullWidth
                />
              }
            />
          </div>
        )}
        {(dataType === "controls" &&
          <div className="form-field guidance">
            <Controller
              name="guidance"
              control={control}
              render={({ field }) =>
                <TextField
                  {...field}
                  multiline
                  rows={4}
                  label="Guidance"
                  fullWidth
                />
              }
            />
          </div>
        )}

        {(dataType === "project_evaluations" &&
          <div className="form-field comments">
            <Controller
              name="comments"
              control={control}
              render={({ field }) =>
                <TextField
                  {...field}
                  multiline
                  rows={4}
                  label="Comments"
                  fullWidth
                />
              }
            />
          </div>)}

        {(dataType !== "project_groups" &&
          dataType !== "control_classes" &&
          dataType !== "control_families" &&
          dataType !== "control_phases" &&
          dataType !== "keyword" &&
          dataType !== "risk_categories"
        ) && (
            <div className="form-field keywords">
              <div className="form-field existing keywords">
                <label>Existing Keywords</label>
                <Controller
                  name="keywordSelect"
                  control={control}
                  defaultValue={"0"}
                  render={({ field }) =>
                    <Select
                      {...field}
                      value={keywordId ? keywordId : "0"}
                      onChange={handleChangeKeywordSelect}
                      inputProps={{ "data-testid": "keyword" }}
                      defaultValue={"0"}
                    >
                      {
                        keywords?.map((word) => {
                          return <MenuItem
                            value={word.id}
                            key={word.id}>
                            {word.name}
                          </MenuItem>
                        })
                      }
                    </Select>
                  }
                />
              </div>
              <ul>
                {(keywordSelections && (
                  keywordSelections.map((word) => {
                    return <li>
                      {word.name}
                      <IconButton aria-label="delete">
                        <DeleteIcon onClick={() => { removeKeyword(word); }} />
                      </IconButton>
                    </li>
                  })
                )
                )}
              </ul>
              <div className="form-field newkeywords">
                <Controller
                  name="newKeywords"
                  control={control}
                  render={({ field }) =>
                    <TextField
                      {...field}
                      label="Add new keywords"
                      //fullWidth
                      helperText="Enter your keyword in all lowercase with a comma and no spaces between each one e.g. legal,technical"
                    />

                  }
                />
              </div>
            </div>
          )
        }
        {(dataType === "frameworks" && isSuperUser &&
          <FormGroup className="watch-switch">
            <FormControlLabel control={
              <Switch checked={frameworkIsGlobal} onChange={() => { handleSwitchChange() }} />} label="Is Global" />
          </FormGroup>
        )}
        {(dataType === "projects" && (
          <>
            <div className="form-field project-groups">
              <label>Project Group</label>
              <Controller
                name="projectGroup"
                control={control}
                defaultValue={"0"}
                render={({ field }) =>
                  <Select
                    {...field}
                    onChange={handleChangeProjectGroupSelect}
                    value={projectGroupId ? projectGroupId : '0'}
                    inputProps={{ "data-testid": "project-group" }}
                    defaultValue={"0"}
                  >
                    {
                      projectGroups?.map((group) => {
                        return <MenuItem
                          value={group.value}
                          key={group.value}>
                          {group.label}
                        </MenuItem>
                      })
                    }
                  </Select>

                }
              />
              <FormHelperText>Please select a project group</FormHelperText>
            </div>
            <div className="form-field project-admin">
              <label>Project Admin *</label>
              <Controller
                name="projectAdmin"
                control={control}
                defaultValue={"0"}
                render={({ field }) =>
                  <Select
                    {...field}
                    onChange={handleChangeAdminSelect}
                    value={projectAdminId ? projectAdminId : '0'}
                    inputProps={{ "data-testid": "project-admin" }}
                    defaultValue={"0"}
                  >
                    {
                      projectAdmin?.map((user) => {
                        return <MenuItem
                          value={user.value}
                          key={user.value}>
                          {user.label}
                        </MenuItem>
                      })
                    }
                  </Select>}
              />
              <FormHelperText>Please select a project administrator</FormHelperText>
            </div>
            <div className="form-field project-status">
              <label>Project Status</label>
              <Controller
                name="projectStatus"
                control={control}
                defaultValue={"0"}
                render={({ field }) =>
                  <Select
                    {...field}
                    onChange={handleChangeProjectStatusSelect}
                    value={projectStatus ? projectStatus : "0"}
                    inputProps={{ "data-testid": "project-status" }}
                    defaultValue={"0"}
                  >
                    {
                      projectStatusValues?.map((value) => {
                        return <MenuItem
                          value={value.value}
                          key={value.value}>
                          {value.label}
                        </MenuItem>
                      })
                    }
                  </Select>

                }
              />
              <FormHelperText>Please select a project status</FormHelperText>
            </div>
          </>
        ))}
        <div>
          {
            (editDataObj && <Button type="submit" variant="contained" >Submit</Button>) ||
            (!editDataObj && <Button type="submit" variant="contained" >Create</Button>)
          }
        </div>
      </form>
    </>
  )
}