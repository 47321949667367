import React, { useEffect, useState } from 'react';
import TextField from "@mui/material/TextField";
import Button from '@mui/material/Button';
import { useForm, Controller } from "react-hook-form";
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import axios from 'axios';
import { MenuItem, Select } from '@mui/material';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

export default function CreateEditUser({ editUserObj }) {
  console.log('editUserObj', editUserObj)
  const [errMsg, setErrMsg] = useState("");
  const [profilepic, setProfilePic] = useState(null);
  const [profilepicType, setProfilepicType] = useState('');
  const [systemRoleId, setSystemRoleId] = useState(editUserObj?.system_role);
  const [phoneNo, setPhoneNo] = useState(editUserObj?.phone_no);
  //default option when no value selected in drop down
  const selectOption = { value: "0", label: "Please select..." };
  const systemRoles = [
    selectOption,
    { value: "1", label: "Project Manager" },
    { value: "2", label: "Analyst" },
    { value: "3", label: "Auditor" },
    { value: "4", label: "System Administrator" },
    { value: "6", label: "Project Stakeholder" },
  ]

  // handle change events on dropdowns
  const handleChangeSystemRoleSelect = (event) => {
    setSystemRoleId(event.target.value);
  }

  function handleProfilePicture(event) {
    const file = event.target.files[0];
    const maxFileSize = 7 * 1024 * 1024;

    if (file.size > maxFileSize) {
      alert('Error: File size is too large. Please upload a file less than 7MB')
    } else {

      // imageCompression(file, options).then(function (compressedFile) {// smaller than maxSizeMB
      setProfilePic(URL.createObjectURL(file));
      setProfilepicType(file);
    }
  }

  useEffect(() => {
  }, [errMsg])

  useEffect(() => {
  }, [editUserObj])


  const { control, handleSubmit } = useForm({
    defaultValues: {
      email: editUserObj?.email,
      firstName: editUserObj?.first_name,
      lastName: editUserObj?.last_name,
      systemRoleSelect: {},
    }
  });
  const onSubmit = async (data) => {
    await postFormData(data);
    if (profilepic) {
      await postProfilePic();
    }

    if (!profilepic) {
      window.location.reload(false);
    }

  }

  async function postFormData(data) {
    var url;
    if (!editUserObj) {
      url = `${process.env.REACT_APP_API_URL}/users`;
    }
    var body = JSON.stringify(
      {
        email: data.email,
        first_name: data.firstName,
        last_name: data.lastName,
        phone_no: phoneNo,
        system_role: systemRoleId,
      })
    var method = "POST";
    //if a data object is being edited
    if (editUserObj) {
      method = "PUT";
      url = `${process.env.REACT_APP_API_URL}/users/${editUserObj.id}/update_profile`;
    }
    try {
      await axios(url, {
        method: method,
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.access_token}`,
        },
        data: body,
      })

    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response");
      } else if (err.response?.status) {
        //console.log(err.response?.status)
        const status = String(err.response?.status)
        if (status === "422") {
          setErrMsg(`Validation error.`)
        } else {
          setErrMsg(`There was a ${err.response?.status} error`)
        }
      } else {
        setErrMsg("There was an error")
      }
    }
  }


  async function postProfilePic() {
    var url = `${process.env.REACT_APP_API_URL}/users/${editUserObj.id}/update_profile_picture`;
    var method = "PUT";
    var formData = new FormData();
    formData.append("profile_picture", profilepicType)

    var postHeaders = {
      "Content-Type": "multipart/form-data",
      "Authorization": `Bearer ${localStorage.access_token}`,
    }
    try {
      await axios(url, {
        method: method,
        headers: postHeaders,
        data: formData,
      })
      window.location.reload(false);
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response");
      } else if (err.response?.status) {
        //console.log(err.response?.status)
        const status = String(err.response?.status)
        if (status === "422") {
          setErrMsg(`Validation error.`)
        } else {
          setErrMsg(`There was a ${err.response?.status} error`)
        }
      } else {
        setErrMsg("There was an error")
      }
    }
  }

  return (
    <div className='create edit user'>
      {errMsg ? (
        <Stack sx={{ width: '100%' }} spacing={2} className="alerts">
          <Alert severity="error">{errMsg}</Alert>
        </Stack>
      ) : (
        <></>
      )}
      {(editUserObj && <h2>Edit User</h2>)}
      {(!editUserObj && <h2>Create User</h2>)}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-field control">
          <label>System Role</label>
          <Controller
            name="systemRoleSelect"
            control={control}
            defaultValue={"0"}
            render={({ field }) =>
              <Select
                {...field}
                onChange={handleChangeSystemRoleSelect}
                value={systemRoleId ? systemRoleId : "0"}
                defaultValue={"0"}
                inputProps={{ "data-testid": "system-role" }}
              >
                {
                  systemRoles?.map((role) => {
                    return <MenuItem
                      value={role.value}
                      key={role.value}>
                      {role.label}
                    </MenuItem>
                  })
                }
              </Select>
            }
          />
        </div>
        {(editUserObj && <div className="form-field file">
          <label>Upload profile picture</label>
          <input
            type="file"
            onChange={handleProfilePicture}
            accept=".jpeg, .png, .jpg"
          />
          <img className="profile-pic" src={profilepic ? profilepic : editUserObj.profile_picture} alt={`User profile`} />
        </div>)}
        <div className="form-field email">
          <Controller
            name="email"
            control={control}
            render={({ field }) =>
              <TextField
                required
                label="Email"
                {...field}
                fullWidth
                inputProps={{ "data-testid": "email" }}
              />
            }
          />
        </div>
        <div className="form-field first-name">
          <Controller
            name="firstName"
            control={control}
            render={({ field }) =>
              <TextField
                label="First name"
                {...field}
                fullWidth
                inputProps={{ "data-testid": "first-name" }}
              />
            }
          />
        </div>
        <div className="form-field last-name">
          <Controller
            name="lastName"
            control={control}
            render={({ field }) =>
              <TextField
                label="Last name"
                {...field}
                fullWidth
                inputProps={{ "data-testid": "last-name" }}
              />
            }
          />
        </div>
        <div>
          <label>Cell phone for sms notifications</label>
          <PhoneInput
            country={'us'}
            regions={'north-america'}
            value={phoneNo}
            onChange={phone => setPhoneNo(phone)}
          />
        </div>
        {(editUserObj && <Button type="submit" variant="contained" >Save</Button>)}
      </form>
    </div>
  );
}