import React, { useEffect, useState, useCallback, useMemo } from "react";
import Button from '@mui/material/Button';
import { MenuItem, Select } from '@mui/material';
import { useForm, Controller } from "react-hook-form";
import axios from 'axios';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

//This class is for the create / edit control form on a project

export default function CreateEditTaskProjectAuditTest({
  isOpen,
  projectId,
  controlData,
  taskData
}) {
  //axios error message
  const [errMsg, setErrMsg] = useState("");
  useEffect(() => {
  }, [errMsg])

  //used with select fields in form
  const [auditTestId, setAuditTestId] = useState();

  //dropdown values 
  const [auditTests, setAuditTests] = useState([]);

  //default option when no value selected in drop down
  const selectOption = useMemo(() => { return { value: "0", label: "Please select..." } }, [])

  const { control, handleSubmit } = useForm({
    defaultValues: {
      auditTestSelect: {},
    }
  });

  // handle change events on dropdowns
  const handleChangeAuditTestSelect = (event) => {
    setAuditTestId(event.target.value);
  }

  /* form submit */
  const onSubmit = async (data) => {
    const response = await postFormData(data);
    if (response.data) {
      controlData(response.data)
    } else {
      setErrMsg(`${response}`)
    }
    isOpen(false);
  }

  async function postFormData(data) {
    var method = "PUT";
    var project_audit_tests = []
    if (taskData.audit_tests) {
      for (let p = 0; p < taskData.audit_tests.length; p++) {
        project_audit_tests.push(taskData.audit_tests[p].AuditTest.id)
      }
    }
    project_audit_tests.push(auditTestId)
    var body = {
      "audit_tests": project_audit_tests,
      "comments": "audit test added to task"
    }
    var url = `${process.env.REACT_APP_API_URL}/tasks/${taskData?.id}`;
    const response = axios(url, {
      method: method,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.access_token}`,
      },
      data: body,
    }).then(result => {
      return result;
    }).catch(error => { return error.message; })
    return response;
  }
  const getProjectData = useCallback(async () => {
    var url = `${process.env.REACT_APP_API_URL}/projects/${projectId}`;

    const response = axios(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.access_token}`,
      },
    }).then(result => {
      return result;
    }).catch(error => { return error.message; })
    return response
  }, [projectId])

  const getDropDownOptions = useCallback(async () => {
    const jsonData = await getProjectData();
    var tmpAuditTestOptions = [];
    jsonData.data.audit_tests.forEach((audit_test) => {
        tmpAuditTestOptions.push(
        {
          value: audit_test.id,
          label: audit_test.name
        }
      )
    })
    if (tmpAuditTestOptions.length < 1) {
      setErrMsg("There are no addable audit tests available. Please add one to your project.");
    }
    // for (let x = 0; x < tmpAuditTestOptions.length; x++) {
    //   for (let y = 0; y < taskData.audit_tests.length; y++) {
    //     if (parseInt(tmpAuditTestOptions[x].value) === taskData.audit_tests[y].TaskAuditTest.audit_test_id) {
    //       tmpAuditTestOptions.splice(x, 1)
    //     }
    //   }
    // }
    var allOptions = [selectOption, ...tmpAuditTestOptions];

    setAuditTests(allOptions);
  }, [getProjectData, selectOption])

  useEffect(() => {
    if (auditTests.length === 0) {
        getDropDownOptions();
    }
    
  }, [getDropDownOptions, auditTests.length]);

  return (
    <div className="create edit tak audit test">
      {errMsg ? (
        <Stack sx={{ width: '100%' }} spacing={2} className="alerts">
          <Alert severity="error">{errMsg}</Alert>
        </Stack>
      ) : (
        <></>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-field risk">
          <label>Audit Test</label>
          <Controller
            name="auditTestSelect"
            control={control}
            defaultValue={0}
            render={({ field }) =>
              <Select
                {...field}
                onChange={handleChangeAuditTestSelect}
                defaultValue={0}
                value={auditTestId ? auditTestId : 0}
              >
                {
                  auditTests?.map((at) => {
                    return <MenuItem
                      value={at.value}
                      key={at.value}>
                      {at.label}
                    </MenuItem>
                  })
                }
              </Select>
            }
          />
        </div>

        <div>
          <Button type="submit" variant="contained" >Submit</Button>
        </div>
      </form>
    </div>
  )
}