import React, { useEffect, useState } from "react";
import '../css/Register.css';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Loader from './Loader';
import axios from 'axios';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Logo from '../assets/fedrisk-full-logo.png';
import RegisterOTP from "./RegisterOTP";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import StyledTextField from "./forms/StyledTextField";
import PasswordChecklist from "react-password-checklist"


export async function checkUniqueEmail(email) {
  var method = "POST";
  var url = `${process.env.REACT_APP_API_URL}/tenants/check_unique_email?email=${email}`
  const response = axios(url, {
    method: method,
    headers: {
      "Content-Type": "application/json",
    },
  }).then(result => {
    return result;
  }).catch(error => { return error.message; })
  return response;
}

export default function Register() {

  const [errMsg, setErrMsg] = useState("");
  const [organization, setOrganization] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [emailValid, setEmailValid] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [displayOtp, setDisplayOtp] = useState(false);
  const [regData, setRegData] = useState({});
  const [phoneNo, setPhoneNo] = useState();
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [firstNameValid, setFirstNameValid] = useState('default');
  const [lastNameValid, setLastNameValid] = useState('default');
  const [organizationValid, setOrganizationValid] = useState('default');
  const [isPasswordValid, setIsPasswordValid] = useState('default');
  const [isConfirmPasswordValid, setIsConfirmPasswordValid] = useState('default');

  const validateName = (name) => {
    const re = /^[A-Za-z]*$/;
    return re.test(name) && name.length > 0;
  };

  const handleFirstNameChange = (e) => {
    const value = e.target.value;
    setFirstName(value);
    // setFirstNameError(!validateName(value) && value !== '');
    setFirstNameValid(value === '' ? 'default' : validateName(value) ? 'true' : 'false');
  };

  const handleLastNameChange = (e) => {
    const value = e.target.value;
    setLastName(value);
    setLastNameValid(value === '' ? 'default' : validateName(value) ? 'true' : 'false');
  };

  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email) && email.length > 0;
  };

  const handleEmailChange = (e) => { 
    const value = e.target.value;
    setEmail(value);
    setEmailValid(value === '' ? 'default' : validateEmail(value) ? 'true' : 'false');
  };


  useEffect(() => {
    //setValidEmail(EMAIL_REGEX.test(email));
  }, [email]);

  useEffect(() => {
    //setValidPwd(PWD_REGEX.test(password));
    //setValidMatch(password === matchPwd);
  }, [password]);

  const validatePassword = (password) => {
    const minLengthValid = password.length >= 9;
    const specialCharValid = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    const numberValid = /\d/.test(password);
    const capitalValid = /[A-Z]/.test(password);
    const noSpaces = !password.includes(' ');

    return minLengthValid && specialCharValid && numberValid && capitalValid && noSpaces;
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);
    setIsPasswordValid(value === '' ? 'default' : validatePassword(value) ? 'true' : 'false');
  };

  const handleOrganizationChange = (e) => {
    const value = e.target.value;
    setOrganization(value);
    setOrganizationValid(value === '' ? 'default' : validateName(value) ? 'true': 'false');
  };

  //useEffect(() => {
  //setErrMsg("");
  //}, [email, password, matchPwd]);

  useEffect(() => {
  }, [errMsg])

  const isOpen = () => {
    handleClose();
  }

  const handleClose = () => {
    setDisplayOtp(false);
  }

  const handleSubmit = async e => {
    setLoading(true);
    e.preventDefault();
    if (password !== confirmPassword) {
      alert(`Your passwords do not match`);
      setPassword("");
      setConfirmPassword("");
      setLoading(false);
      return
    }
    else {
      setUpAccount();
    }

  }
  const registerTenant = async () => {
    //var body = registration;
    //body["otp"] = otp;
    var method = "POST";
    var url = `${process.env.REACT_APP_API_URL}/tenants/register`
    const response = axios(url, {
      method: method,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        //"otp": otp,
        "organization": organization,
        "first_name": firstName,
        "last_name": lastName,
        "email": email,
        "phone_no": phoneNo,
        "password": password,
        "confirm_password": confirmPassword
      },
    }).then(result => {
      return result;
    }).catch(error => { return error.message; })
    return response;
  }

  const setUpAccount = async () => {
    const checkEmail = await checkUniqueEmail(email);
    //console.log(`check email response = ${JSON.stringify(checkEmail)}`)
    if (checkEmail !== `Request failed with status code 409`) {
      const resp = await registerTenant();
      if (!resp.data) {
        alert(`There was an error registering your account.`)
        setLoading(false);
        return
      }
      setRegData(resp.data);
      // const resp = await sendOtpCode();
      // if (!resp.data) {
      //   alert(`There was an error getting the OTP code.`)
      //   setLoading(false);
      //   return
      // }
      setDisplayOtp(true);
      setLoading(false);
    }
    if (checkEmail === `Request failed with status code 409`) {
      setErrMsg(`The email ${email} is already in use. Please use a unique email address.`)
      setLoading(false);
    }


  }

  // async function sendOtpCode() {
  //   var emailparse = email.replace('@', '%40')
  //   var method = "GET";
  //   var url = `${process.env.REACT_APP_API_URL}/tenants/get-tenant-registration-otp?email=${emailparse}`
  //   const response = axios(url, {
  //     method: method,
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //   }).then(result => {
  //     return result;
  //   }).catch(error => { return error.message; })
  //   return response;
  // }

  if (loading) {
    return (
      <Loader />
    )
  }
  if (displayOtp) {
    return (
      <RegisterOTP isOpen={isOpen} registration={regData} />
    )
  }

  return (
    <div className="register-wrapper">
      <img src={Logo} alt="Logo" width="481px" />
      <h1>Create an account</h1>
      <div className="login-here">Already have an account? <a href="/">Log In</a></div>
      {errMsg ? (
        <Stack sx={{ width: '100%' }} spacing={2} className="alerts">
          <Alert severity="error">{errMsg}</Alert>
        </Stack>
      ) : (
        <></>
      )}
      <Box
        sx={{
          '& .MuiTextField-root': { m: 1, width: '100%' },
        }}
      >
        <form onSubmit={handleSubmit}>
          <div>
            <StyledTextField 
              required 
              id="organization" 
              value={organization} 
              label="Organization name" 
              onChange={handleOrganizationChange}
              error={organizationValid === 'false'}
              helperText={organizationValid === 'false' ? 'Organization name must contain only letters' : ''}
              isvalid={organizationValid}
              style={{
                width: 400
              }}
              inputProps={{ maxLength: 25 }}
            />
          </div>
          <h2>Your Account Administrator</h2>
          <div>
            <StyledTextField 
              required 
              id="firstName" 
              value={firstName} 
              label="First name" 
              onChange={handleFirstNameChange}
              error={firstNameValid === 'false'}
              helperText={firstNameValid === 'false' ? 'First name must contain only letters' : ''}
              isvalid={firstNameValid}
              style={{
                width: 400
              }}
              inputProps={{ maxLength: 25 }}
            />
            
            <StyledTextField
              required
              id="lastName"
              value={lastName}
              label="Last name"
              onChange={handleLastNameChange}
              error={lastNameValid === 'false'}
              helperText={lastNameValid === 'false' ? "Last name must contain only letters" : " "}
              isvalid={lastNameValid}
              style={{
                width: 400
              }}
              inputProps={{ maxLength: 25 }}
            />
            </div>
          <div>
            <label>Cell phone for sms notifications</label>
            <PhoneInput
              country={'us'}
              regions={'north-america'}
              value={phoneNo}
              onChange={phone => setPhoneNo(phone)}
            />
          </div>
          <div>
            <StyledTextField 
              type="email" 
              required 
              id="email" 
              value={email} 
              label="Email" 
              onChange={handleEmailChange}
              error={emailValid === 'false'}
              helperText={emailValid === 'false' ? 'Please enter a valid email address' : ''}
              isvalid={emailValid}
              style={{
                width: 500
              }} 
              inputProps={{ maxLength: 50 }}

            />
          </div>
          <h2>Create a Password</h2>
          <div>
            <StyledTextField type={showPassword ? 'text' : 'password'} required label="Password" id="password" 
              value={password}
              isvalid={isPasswordValid}
              InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
              onChange={handlePasswordChange}
              onFocus={() => setIsPasswordFocused(true)}
              onBlur={() => setIsPasswordFocused(false)}
              style={{
                width: 500
              }}
              inputProps={{ maxLength: 255 }}
              error={password !== '' && !isPasswordValid}
              // helperText={password !== '' && !isPasswordValid ? 
              //   "Password must meet all criteria and not contain spaces" : " "}
            />
          {isPasswordFocused && (
            <>
            {/* <PasswordStrengthBar
              password={password}
              style={{
                width: 483,
                marginTop: 5,
                marginBottom: 5,
                marginInlineStart: 13
              }}
              minLength={8}
              onChangeScore={(score) => setPasswordStrength(score)}
            /> */}
            </>
          )}
          </div>
          <div>
            <StyledTextField 
              type={showConfirmPassword ? 'text' : 'password'} 
              required label="Confirm Password" id="confirmPassword" 
              value={confirmPassword}
              isvalid={isConfirmPasswordValid}
              InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                      onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                      edge="end"
                      >
                        {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              onChange={e => setConfirmPassword(e.target.value)}
              onFocus={() => setIsPasswordFocused(true)}
              onBlur={() => setIsPasswordFocused(false)}
                style={{
                  width: 500
                }}
                inputProps={{ maxLength: 255 }}

          />
          {isPasswordFocused && (
            <div>
              {/* pad passwordchecklist to the right */}
              <PasswordChecklist
                      rules={["minLength","specialChar","number","capital","noSpaces","match"]}
                      minLength={9}
                      value={password}
                      valueAgain={confirmPassword}
                      onChange={(isValid) => setIsConfirmPasswordValid(isValid ? 'true' : 'false')}
                      style={{
                        width: 500,
                        marginLeft: 13,
                        // marginTop: 5,
                        marginBottom: 13
                      }}
                    />
            </div>
          )}
          </div>
          <div>
          {/* {passwordStrength < 4 && password !== '' && (
              <p style={{color: 'red', marginLeft: 13}}>
                Password must be strong to continue
              </p>
            )} */}
            <Button className="sign-in" type="submit" variant="contained" 
            disabled={
                isConfirmPasswordValid !== 'true' || 
                firstNameValid !== 'true' || 
                lastNameValid !== 'true' || 
                organizationValid !== 'true' || 
                emailValid !== 'true' || 
                isPasswordValid !== 'true'
              }
             >
                Continue
            </Button>
          </div>
        </form>
      </Box>
    </div>
  )
}