import React, { useEffect, useState, useCallback } from "react";
import { DataGrid } from '@mui/x-data-grid';
import Link from '@mui/material/Link';
import SpinnerComponent from '../LoadingSpinner';
import axios from 'axios';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from '@mui/icons-material/Edit';
import CreateEdit from "../CreateEdit";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import AddIcon from '@mui/icons-material/Add';

//This class displays lists of keywords

export async function loadData() {
    //call data endpoint for data type to set rows and columns
    var url = `${process.env.REACT_APP_API_URL}/keyword`
    const response = axios(url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.access_token}`,
        },
    }).then(result => {
        return result;
    }).catch(error => { return error.message; })
    return response;
}

export async function deleteData(id) {
    var body;
    var url = `${process.env.REACT_APP_API_URL}/keyword/${id}`;
    const response = axios(url, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.access_token}`,
        },
        data: body,
    }).then(result => {
        return result;
    }).catch(error => { return error.message; })
    return response;
}

const columns = [
    { field: 'id', headerName: 'id', width: 120 },
];

const rows = [
    { id: "n/a" },
];

export default function KeywordList() {
    //axios error message
    const [errMsg, setErrMsg] = useState("");

    const [rowsData, setRowsData] = useState([]);
    const [colsData, setColsData] = useState(null);

    const [loading, setLoading] = useState(false);

    const [keywordListData, setKeywordListData] = useState(null);

    const [openCreateDialog, setOpenCreateDialog] = useState(null);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(null);

    const [editObj, setEditObj] = useState(null);

    const [selected, setSelected] = useState([]);

    const handleClose = () => {
        setOpenCreateDialog(false);
        setOpenDeleteDialog(false);
        setEditObj(null);
    }

    async function deleteSelectedRow() {
        if (rowsData?.length > 0) {
            var filteredArray = [...rowsData]
            for (let x = 0; x < selected.length; x++) {
                const response = await deleteData(selected[x]);
                if (response?.status !== 200) {
                    setErrMsg(`${response}`);
                }
                //setErrMsg(``)
                filteredArray = [...filteredArray.filter((row) => row.id !== selected[x])]
            }
            setRowsData(filteredArray);
        }

    };

    const onRowsSelectionHandler = (ids) => {
        setSelected([...ids]);
    };

    const getData = useCallback(async () => {
        setLoading(true)
        var response = await loadData();

        if (!response?.data) {
            setErrMsg(`No data found`)
        }
        setKeywordListData(response)
        if (response?.data) {
            var filteredRowData = [];
            response.data.forEach((key) => {
                filteredRowData.push({
                    id: key["id"],
                    name: key["name"],
                    created_date: key["created_date"],
                    last_updated_date: key["last_updated_date"],
                });
            })
            var colsVals = [
                {
                    field: 'name',
                    headerName: 'Name',
                    width: 300,
                    renderCell: (params) => (
                        <Link href={`/keywords/${params.row.id}`}>{params.value}</Link>
                    )
                },
                {
                    field: 'created_date',
                    headerName: 'Created date',
                    width: 200,
                    cellClass: "normalLineHeight"
                },
                {
                    field: 'last_updated_date',
                    headerName: 'Last updated date',
                    width: 200,
                    cellClass: "normalLineHeight"
                },
                {
                    field: 'action',
                    headerName: 'Action',
                    width: 200,
                    renderCell: (params) => (
                        <>
                            <Button startIcon={<EditIcon />} onClick={() => { setEditObj(params.row); setOpenCreateDialog(true) }} />
                            <Button startIcon={<DeleteIcon />} onClick={() => { setEditObj(params.row); setOpenDeleteDialog(true) }} />
                        </>

                    )

                },
            ];
            setRowsData([...filteredRowData])
            setColsData(colsVals)
        }
        setLoading(false)
    }, [])



    useEffect(() => {
        if (keywordListData === null) {
            getData();
        }
    }, [getData, keywordListData]);

    
    // useEffect(() => {
    //     console.log('rowsData', rowsData)
    // }, [rowsData])

    // useEffect(() => {
    //     console.log('colsData', colsData)
    // }, [colsData])

    return (<>

        <h1 className="keywords-title">Keywords Management</h1>
        <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/system-administration">
                System Administration
            </Link>
            <Typography color="text.primary">Keywords</Typography>
        </Breadcrumbs>

        <div style={{ marginTop: '60px' }} className="keywordList">
            <>
                {errMsg ? (
                    <Stack sx={{ width: '100%' }} spacing={2} className="alerts">
                        <Alert severity="error">{errMsg}</Alert>
                    </Stack>
                ) : (
                    <></>
                )}
                <div className="keyword list" style={{ height: '600px', width: '100%' }}>
                    <Button
                        variant="contained"
                        className="secondary btn"
                        startIcon={<DeleteIcon />}
                        disabled={!selected.length}
                        onClick={() =>
                            selected.length ? setOpenDeleteDialog(true) : null
                        }
                    >
                        Delete
                    </Button>
                    <Button
                        startIcon={<AddIcon />}
                        variant="outlined"
                        onClick={() => { setOpenCreateDialog(true); }}
                    >
                        Create
                    </Button>
                    <DataGrid
                        getRowClassName={(params) =>
                            params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'
                        }
                        rows={rowsData ? rowsData : rows}
                        columns={colsData ? colsData : columns}
                        rowsPerPageOptions={[10, 25, 50, 100]}
                        pageSize={10}
                        checkboxSelection
                        loading={loading}
                        components={{
                            LoadingOverlay: SpinnerComponent,
                        }}
                        onSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
                        getRowHeight={() => 'auto'}
                    />
                </div>
            </>
        </div>
        <Dialog
            //fullScreen
            open={openCreateDialog ? openCreateDialog : false}
            onClose={handleClose}
            aria-labelledby="create-data"
            aria-describedby="alert-dialog-create-data"
        >
            <DialogActions className="create-edit-dialog">
                <Button onClick={handleClose}>Close</Button>
            </DialogActions>
            <DialogTitle id="alert-dialog-title">
                {editObj ? `Edit keyword` : `Create keyword`}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <CreateEdit dataType={`keyword`} editDataObj={editObj} />
                </DialogContentText>
            </DialogContent>
        </Dialog>
        <Dialog
            open={openDeleteDialog ? openDeleteDialog : false}
            onClose={handleClose}
            aria-labelledby="delete-confirm"
            aria-describedby="alert-dialog-delete-confirm"
        >
            <DialogTitle id="alert-dialog-title">
                {"Delete?"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Are you sure you want to delete this keyword?
                </DialogContentText>
            </DialogContent>
            <DialogActions className="create-edit-dialog">
                <Button onClick={handleClose}>Close</Button>
                <Button onClick={() => { handleClose(); deleteSelectedRow(); }} autoFocus>
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    </>
    )

}