import React, {
    useCallback,
    useMemo,
    useRef,
    useState,
    useEffect,
} from "react";
import axios from 'axios';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import { LicenseManager } from 'ag-grid-enterprise'
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import Button from '@mui/material/Button';
import PrintIcon from '@mui/icons-material/Print';
import { useReactToPrint } from 'react-to-print';

LicenseManager.setLicenseKey("[TRIAL]_this_{AG_Charts_and_AG_Grid}_Enterprise_key_{AG-059381}_is_granted_for_evaluation_only___Use_in_production_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_purchasing_a_production_key_please_contact_info@ag-grid.com___You_are_granted_a_{Single_Application}_Developer_License_for_one_application_only___All_Front-End_JavaScript_developers_working_on_the_application_would_need_to_be_licensed___This_key_will_deactivate_on_{14 June 2024}____[v3]_[0102]_MTcxODMxOTYwMDAwMA==d5231535e6cc422dcca3530626051086")

var savedState;

var savedPivotMode;

var savedChartModels;

var savedSelectedRows;

var savedValueColumns;

var savedGridColumns;


const ReportingPivot = () => {

    //const pivots = useSelector(selectAllPivot)

    const [response, setResponse] = useState(null)


    useEffect(() => {
        //console.log('response', response)
    }, [response])

    const contentToPrint = useRef(null);
    const handlePrint = useReactToPrint({
        documentTitle: "Print This Document",
        onBeforePrint: () => console.log("before printing..."),
        onAfterPrint: () => console.log("after printing..."),
        removeAfterPrint: true,
    });

    const gridRef = useRef();
    const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
    const [rowData, setRowData] = useState();

    useEffect(() => {
        console.log('rowData', rowData)
    }, [rowData])

    const columnDefs = [
        {
            field: "project_group_name",
            headerName: `Project Groups`,
            enableRowGroup: true,
            enablePivot: true,
            rowGroup: true,
            groupDefaultExpanded: 1,
        },
        {
            field: "status",
            enableRowGroup: true,
            enablePivot: true,
            groupDefaultExpanded: 1,
            rowGroup: true,
        },
        {
            field: "created_date",
            enableRowGroup: true,
            enablePivot: true,
            rowGroup: false,
        },
        {
            field: "name",
            enableRowGroup: true,
            enablePivot: false,
            rowGroup: true,
            groupDefaultExpanded: 1,
        },
        { field: "risk_low_count", aggFunc: "sum", headerName: "Low Risks" },
        { field: "risk_low_medium_count", aggFunc: "sum", headerName: "Low-Med Risks" },
        { field: "risk_medium_count", aggFunc: "sum", headerName: "Med Risks" },
        { field: "risk_medium_high_count", aggFunc: "sum", headerName: "Med-High Risks" },
        { field: "risk_high_count", aggFunc: "sum", headerName: "High Risks" },
        { field: "audit_test_not_started", aggFunc: "sum", headerName: "Audit Not Started" },
        { field: "audit_test_on_going", aggFunc: "sum", headerName: "Audit On-Going" },
        { field: "audit_test_complete", aggFunc: "sum", headerName: "Audit Complete" },
        { field: "audit_test_on_hold", aggFunc: "sum", headerName: "Audit On-Hold" },
        { field: "project_control_count", aggFunc: "sum", headerName: "Controls" },
        {
            field: "control_mitigation_percent",
            headerName: "Control Mit %",
            aggFunc: "avg"
        },
    ];

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 150,
            filter: true,
        };
    }, []);
    const autoGroupColumnDef = useMemo(() => {
        return {
            minWidth: 300,
        };
    }, []);

    const loadPivots = useCallback(async () => {
        var url = `${process.env.REACT_APP_API_URL}/dashboards/reporting/reporting_settings`;
        const response = axios(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.access_token}`,
            },
        }).then(result => {
            return result;
        }).catch(error => { return error.message; })
        return response;
    }, [])

    const loadData = useCallback(async () => {
        //call data endpoint for data type to set rows and columns
        var url = `${process.env.REACT_APP_API_URL}/dashboards/reporting/pivot`;
        const response = axios(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.access_token}`,
            },
        }).then(result => {
            return result;
        }).catch(error => { return error.message; })
        return response;
    }, [])

    const getData = useCallback(async () => {

        const jsonData = await loadData();

        if (jsonData === "Request failed with status code 404") {
        }
        else {
            setRowData(jsonData.data)
        }

    }, [loadData])

    const createGraph = useCallback((graph) => {
        gridRef.current.api.createRangeChart({
            //chartContainer: document.querySelector("#myChart1"),
            cellRange: {
                columns:graph.cellRange.columns,
                rowStartIndex: graph.cellRange.rowStartIndex,
                rowEndIndex: graph.cellRange.rowEndIndex,
                //rowStartPinned: graph.cellRange.rowStartPinned,
                //rowEndPinned: graph.cellRange.rowEndPinned,
            },
            chartId: graph.chartId,
            chartOptions: graph.chartOptions,
            chartType: graph.chartType,
            chartPalette: graph.chartPalette,
            chartThemeName: graph.chartThemeName,
            modelType: graph.modelType,
            suppressChartRanges: graph.suppressChartRanges,
            unlinkChart: graph.unlinkChart,
            version: graph.version,
        });
    }, []);

    const getPivotSettings = useCallback(async () => {

        const resp = await loadData();

        //console.log(resp)

        if (resp === "Request failed with status code 404") {
        }
        else {
            setRowData(resp.data)
            gridRef.current.api.redrawRows()
        }

        const jsonData = await loadPivots();

        if (jsonData === "Request failed with status code 404") {
        }
        if (jsonData.data === "There are no settings for this user") {

        }
        if (jsonData.data !== "There are no settings for this user") {
            setResponse(jsonData.data)
            let graphs = JSON.parse(jsonData.data.graph_state)
            //console.log(graphs)
            for (let x = 0; x < graphs.length; x++) {
                createGraph(graphs[x])
            }

            let parsedColumnData = jsonData.data
            if (parsedColumnData.length > 0) {
                gridRef.current.columnApi.applyColumnState({
                    state: JSON.parse(parsedColumnData["column_state"]),
                    applyOrder: true,
                })
                gridRef.current.columnApi.setColumnsVisible(JSON.parse(parsedColumnData["grid_cols"]))
                gridRef.current.columnApi.setValueColumns(JSON.parse(parsedColumnData["value_columns"]))
                //gridRef.current.api.restoreChart(JSON.parse(parsedColumnData["graph_state"]))

            }

        }

    }, [loadPivots, createGraph, loadData])

    const onGridReady = useCallback(async (params) => {
        await getData()
        await getPivotSettings()
    }, [getData, getPivotSettings]);

    const onBtnExport = useCallback(() => {
        gridRef.current.api.exportDataAsCsv();
    }, []);

    function stringify(obj) {
        let cache = [];
        let str = JSON.stringify(obj, function (key, value) {
            if (typeof value === "object" && value !== null) {
                if (cache.indexOf(value) !== -1) {
                    // Circular reference found, discard key
                    return;
                }
                // Store value in our collection
                cache.push(value);
            }
            return value;
        });
        cache = null; // reset the cache
        return str;
    }

    const createPivotSettings = useCallback(async (action) => {
        var method = "POST"
        if (action === "update") {
            method = "PUT"
        }
        var url = `${process.env.REACT_APP_API_URL}/dashboards/reporting/reporting_settings`;
        var stateAsJson = JSON.stringify(savedState)
        var graphStateAsJson = JSON.stringify(savedChartModels)
        var selRowAsJson = JSON.stringify(savedSelectedRows)
        var stringforcols = stringify(savedValueColumns)
        var stringforgridcols = stringify(savedGridColumns)
        var body = JSON.stringify(
            {
                user_id: localStorage.getItem("userid"),
                column_state: stateAsJson,
                pivot_state: savedPivotMode,
                graph_state: graphStateAsJson,
                selected_row_state: selRowAsJson,
                value_columns: stringforcols,
                grid_cols: stringforgridcols,
            })
        const response = axios(url, {
            method: method,
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.access_token}`,
            },
            data: body,
        }).then(result => {
            return result;
        }).catch(error => { return error.message; })
        return response;
    }, [])

    // const deletePivotSettings = useCallback(async () => {
    //     var url = `${process.env.REACT_APP_API_URL}/dashboards/reporting/${localStorage.getItem("userid")}`;
    //     const response = axios(url, {
    //         method: "DELETE",
    //         headers: {
    //             "Content-Type": "application/json",
    //             "Authorization": `Bearer ${localStorage.access_token}`,
    //         },
    //     }).then(result => {
    //         return result;
    //     }).catch(error => { return error.message; })
    //     return response;
    // }, [])

    const saveState = useCallback(async () => {
        savedState = gridRef.current.columnApi.getColumnState();
        savedPivotMode = gridRef.current.columnApi.isPivotMode();
        savedChartModels = gridRef.current.api.getChartModels();
        savedSelectedRows = gridRef.current.api.getSelectedRows();
        savedValueColumns = gridRef.current.api.getColumnDefs();
        savedGridColumns = gridRef.current.columnApi.getAllDisplayedColumns();
        gridRef.current.api.destroyChart()
        var responseJson = await loadPivots()
        //post pivot data
        if (responseJson.data === "There are no settings for this user") {
            //post pivot settings
            createPivotSettings("create").then(getPivotSettings())
        }
        if (responseJson.data !== "There are no settings for this user") {
            //update pivot settings
            createPivotSettings("update").then(getPivotSettings())
        }

    }, [createPivotSettings, getPivotSettings, loadPivots]);

    const restoreState = useCallback(() => {
        if (savedState) {
            // Pivot mode must be set first otherwise the columns we're trying to set state for won't exist yet
            gridRef.current.columnApi.setPivotMode("pivotMode", savedPivotMode);
            gridRef.current.columnApi.applyColumnState({
                state: savedState,
                applyOrder: true,
            });
            console.log("column state restored");
        } else {
            console.log("no previous column state to restore!");
        }
    }, []);

    const resetState = useCallback(() => {
        setResponse(null)
        gridRef.current.columnApi.resetColumnState();
        gridRef.current.columnApi.setPivotMode("pivotMode", false);
    }, []);

    const mitigationPercent = useCallback(() => {
        gridRef.current.api.createRangeChart({
            //chartContainer: document.querySelector("#myChart1"),
            cellRange: {
                rowStartIndex: 0,
                rowEndIndex: 4,
                columns: ["control_mitigation_percent"],
            },
            chartType: "bar",
            chartThemeOverrides: {
                common: {
                    title: {
                        enabled: true,
                        text: "Control Mitigation %",
                    },
                },
                bar: {
                    series: {
                        fill: '#007EB3',
                        stroke: 'white',
                        highlightStyle: {
                            item: {
                                fill: 'blue',
                                stroke: 'white',
                                strokeWidth: 4,
                            },
                            series: {
                                dimOpacity: 0.2,
                                strokeWidth: 2,
                            },
                        },
                    },
                },
            },
        });
    }, []);

    const riskSeverity = useCallback(() => {
        gridRef.current.api.createRangeChart({
            //chartContainer: document.querySelector("#myChart2"),
            cellRange: {
                columns: ["risk_low_count", "risk_low_medium_count", "risk_medium_count", "risk_medium_high_count", "risk_high_count"],
            },
            chartType: "bar",
            chartThemeOverrides: {
                common: {
                    title: {
                        enabled: true,
                        text: "Risks",
                    },
                },
                bar: {
                    series: {
                        colors: ['#e6b507', '#016643', '#3ad69f', '#e68507', '#cc0c0c'],
                        stroke: 'white',
                        highlightStyle: {
                            item: {
                                fill: 'blue',
                                stroke: 'white',
                                strokeWidth: 4,
                            },
                            series: {
                                dimOpacity: 0.2,
                                strokeWidth: 2,
                            },
                        },
                    },
                },
            },
        });
    }, []);

    const chartThemeOverrides = useMemo(() => {
        return {
            common: {
                title: {
                    fontSize: 20,
                    fontFamily: 'Arial, sans-serif'
                },
            }
        };
    }, []);

    const processPivotResultColDef = (colDef) => {
        //console.log(colDef)
        /*if (colDef.pivotValueColumn.getId() === 'gold') {
            colDef.headerName = colDef.headerName.toUpperCase();
        }*/
    };
    const processPivotResultColGroupDef = (colGroupDef) => {
        //console.log(colGroupDef)
        /*if (colGroupDef.pivotKeys.length && colGroupDef.pivotKeys[0] === '2010') {
            colGroupDef.headerClass = 'color-background'
        }
        colGroupDef.headerName = 'Year ' + colGroupDef.headerName*/
    };

    // useEffect(() => {

    // }, [columnDefs])

    useEffect(() => {
        //console.log(rowData)
    }, [rowData])

    useEffect(() => {
        // console.log(gridRef)
    }, [gridRef])

    // const restoreChart = useCallback(() => {
    //     if (!savedChartModels) return;
    //     currentChartRef = gridRef.current.api.restoreChart(savedChartModels);
    // }, [savedChartModels]);

    // const createChartContainer = useCallback(
    //     (chartRef) => {
    //         if (currentChartRef) {
    //             currentChartRef.destroyChart();
    //         }
    //         const eChart = chartRef.chartElement;
    //         const eParent = document.querySelector("#myChart");
    //         eParent.appendChild(eChart);
    //         currentChartRef = chartRef;
    //     },
    //     [currentChartRef],
    // );

    return (
        <div style={containerStyle} ref={contentToPrint}>
            <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                <div style={{ marginBottom: "5px" }}>
                    <Button onClick={saveState}>Save State</Button>
                    <Button onClick={restoreState}>Restore State</Button>
                    <Button onClick={() => {
                        handlePrint(null, () => contentToPrint.current);
                    }} startIcon={<PrintIcon />}>Print Report</Button>
                    <Button onClick={resetState}>Reset State</Button>
                    {response ? <></> : <Button onClick={getPivotSettings}>Load last Save</Button>}
                </div>

                <div
                    id="ag-grid-container"
                    style={gridStyle}
                    className={
                        "ag-theme-alpine"
                    }
                >
                    <Button onClick={mitigationPercent}>Add Mitigation %</Button>
                    <Button onClick={riskSeverity}>Add Risks</Button>
                    <Button onClick={onBtnExport}>Download CSV export file</Button>
                    <AgGridReact
                        ref={gridRef}
                        rowData={rowData}
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        autoGroupColumnDef={autoGroupColumnDef}
                        sideBar={true}
                        pivotMode={true}
                        onGridReady={onGridReady}
                        enableCharts={true}
                        chartThemeOverrides={chartThemeOverrides}
                        processPivotResultColDef={processPivotResultColDef}
                        processPivotResultColGroupDef={processPivotResultColGroupDef}
                        enableRangeSelection={true}
                        rowGroupPanelShow='always'
                        groupDefaultExpanded={1}
                        pivotDefaultExpanded={1}
                    />
                    <div id="chartsDisplay">
                        <div id="myChart1" className="ag-theme-alpine"></div>
                        <div id="myChart2" className="ag-theme-alpine"></div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default ReportingPivot;